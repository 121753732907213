import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row, UncontrolledTooltip
} from "reactstrap";
import ReactQuill, { Quill } from "react-quill";
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormAutocompleteGroupingRegion, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ImageLibrary from "views/Hoc/Dialog/ImageLibrary";
import {
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import VideoPreview from "views/Hoc/Dialog/VideoPreview";
import { commonService } from "_services/common.service";
import { Spinner } from "views/Hoc/Spinner";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { retailProfileSchema } from "../profile/validation";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import MultiUpload from "views/Hoc/MultiUpload";
import { MultiSelectImages } from "views/Hoc/Dialog/MultiSelectImages";
import { BannerUploader } from "views/Hoc/ProfileUpload";
import { DocumentName } from "views/Hoc/Dialog/DocumentName";
import { ImageLibraryMultipleUploads } from "views/Hoc/Dialog/ImageLibraryMultipleUploads";
import { BrandCard } from "views/Hoc/BrandCard";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { history } from "_helpers";
import axios from 'axios';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import AnnualTurnOver from "views/Hoc/annualTurnOver";
import bannerPic from '../../../assets/img/channelDetail/Default Banner.png';

const radioGroupOptions1 = [
  {
    id: "1",
    name: "Public sector",
    selected: false
  },
  {
    id: "2",
    name: "Education",
    selected: false
  },
  {
    id: "3",
    name: "Major accounts/ Enterprise",
    selected: false
  },
  {
    id: "4",
    name: "Midcaps",
    selected: false
  },
  {
    id: "5",
    name: "SMB",
    selected: false
  },
  {
    id: "6",
    name: "Micro-companies / self-employed",
    selected: false
  },
  {
    id: "7",
    name: "Household",
    selected: false
  },
  {
    id: "8",
    name: "other (please specify)",
    selected: false
  },

];

var _ = require("lodash");
const RetailReseller = (props) => {
  var userData = JSON.parse(localStorage.getItem("CHuser"));
  let typeId = userData?.channelTypeId;
  const [isAnnualTurn, setAnnualTurn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");
  const [bannerId, setBannerId] = useState("");
  let [namePopup, setNamePopup] = useState(false);
  let [docName, setDocName] = useState(null);
  let [docId, setDocId] = useState(null);
  const [prodImage, setProdImage] = useState(false);
  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: "onBlur",
    resolver: yupResolver(retailProfileSchema)
  });
  console.log('errors', errors);
  const [endCustomerOption, setEndCustomer] = useState(radioGroupOptions1)
  const [open, setOpen] = useState(false);
  const [initialVideo, setInitialVideo] = useState('');
  let [comImage, setComImage] = useState(false);
  const [image, setImage] = useState("");
  let [awardImage, setAwardImage] = useState(false);
  const [state, setState] = useState({
    companyLogo: null,
    companyLogoUrl: null,
    companyName: "",
    legalAddress: "",
    country: "",
    countryList: [],
    zipCode: "",
    city: "",
    regAddressId: "",
    billAddressId: "",
    companyRegNumber: null,
    certificateName: null,
    certificateId: null,
    vatNumber: null,
    vatId: null,
    vatNumberCheck: '',
    vatNumber: '',
    vat_verified: 0,
    phoneNumber1: "",

    ChannelProfileValue: [],
    ChannelProfileOption: [],
    tweetPitch: "",
    sourcing: "",
    companyDesc: "",
    productValue: [],
    productFamilies: [],
    isOEM: null,
    isPhysicalSales: null,
    physicalSales: null,
    endCustomers: null,
    otherEndCustomer: null,

    mobile: "",
    email: "",
    websiteUrl: "",
    linkedIn: "",
    insta: "",
    facebook: '',
    twitter: '',
    tradeUrls: "",
    b2bList: [{ id: Math.random(), value: "" }],
    deleted_B2B: [],
    social_linkedin_id: null,
    social_instagram_id: null,
    social_twitter_id: null,
    social_facebook_id: null,
    awards: [],
    savedAwards: [],
    deletedAwards: [],

    yearOfEstablishment: "",
    no_of_staffs: "",
    annualTurnOver: "",
    annualTurnOverList: [],
    domesticRevenue: null,
    internationalRev: null,
    deleted_companyImages: [],

    introVideo: null,

    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',
    social_twitter_id: null,
    social_facebook_id: null,

    areYouStartUp: '1',
    startUpType: [],

    sellingCountries: [],
    sellingCountriesOption: []

  });

  const [previlege, setPrevilege] = useState(null);
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null, cid: null, JctId: null });
  //Multiupload
  const [mediaState, setMediaState] = useState({
    productBrochures: [],
    bannerImages: [],
    companyImages: [],
  });
  const watchAll = watch([
    "companyName",
    "CRN",
    "VATN",
    "websiteUrl",
    "city",
    "insta",
    "linkedIn",
    "facebook",
    "twitter",
    "zipCode",
    "Yearestablishment",
    "phoneNumber1",
    // "mobile",
    // "email",
    "tradeUrls",
    // "storeCount",
    "physicalSales",
    "otherEndCustomer",
    "startup", 'turnOver', 'staffCnt'
  ]);
  useEffect(() => {

    (async () => {

      await getCompanyDetails();
      await getFactsDetails();


      await setTradelocation();
      await getCountry();

      await getChannelProduct();
      // getInitialProfile();
      await getChannelProfile();
      setLoading(false);

      // if (props.ratingId) {
      //   await getCompanyDataOld();
      // }

      getAccessPrevilege();

    })();

  }, []);

  const getAccessPrevilege = () => {
    (async () => {
      await axios.get('user/userActionPrevilege/' + 2).then(res => {
        setPrevilege(res.data.length > 0 ? res.data[0] : 0);
      }).catch(err => {
        ToastsStore.error('No Access Found!');
      });
    })();
  }

  const getCompanyDataOld = async () => {
    const apiUrl = '/setting/getCompanyDataOld';

    await axios.get(apiUrl).then(res => {
      console.log('response', res.data);

      if (res.data) {
        state.brandList = res.data.brand ? res.data.brand : state.brandList;
        state.brandList.map(x => {
          state.brandOption = state.brandOption.filter(y => y.id != x.id);
        })

        state.companyLogo = res.data.channelLogo ? res.data.channelLogo : state.companyLogo;
        state.documentId = res.data.documentId ? res.data.documentId : state.documentId;
        state.introVideo = res.data.introvideoId ? res.data.introvideoId : state.introVideo;

        if (res.data.banner) {
          setImageSrc(res.data.banner);
          setBannerId(res.data.channelBannerId);
          setMediaState({ ...mediaState, bannerImages: [{ image: res.data.banner, id: res.data.channelBannerId }] });
        }

        if (res.data.introVideo)
          setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.introVideo)
        state.yearOfEstablishment = res.data.estYear ? res.data.estYear : state.yearOfEstablishment;

        state.storeCount = res.data.storeCount ? res.data.storeCount : state.storeCount;
        state.no_of_skus = res.data.no_of_skus ? res.data.no_of_skus : state.no_of_skus;
        state.areYouStartUp = res.data.startup ? res.data.startup : state.areYouStartUp;


        state.startUpType = startUp.map(x => {
          if (res.data.maturitylevel?.filter(y => y == x.value).length)
            return x;
        });

        state.startUpType = state.startUpType.filter(x => x);

        state.domesticRevenue = res.data.revD ? res.data.revD : state.domesticRevenue;
        state.internationalRev = res.data.revI ? res.data.revI : state.internationalRev;
        state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange ? res.data.empCntRange.replace(/\s/g, "").trim() : state.no_of_staffs;
        state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange ? res.data.turnoverRange.replace(/\s/g, "").trim() : state.annualTurnOver;

        if (res.data?.channelSocial?.length) {
          res.data.channelSocial.map(x => {
            if (x.socialSite.socialSiteId == 1) {
              state.social_linkedin_id = x.channelSocialLinkId;
              state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if (x.socialSite.socialSiteId == 3) {
              state.social_instagram_id = x.channelSocialLinkId;
              state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }

            if (x.socialSite.socialSiteId == 4) {
              state.social_twitter_id = x.channelSocialLinkId;
              state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if (x.socialSite.socialSiteId == 5) {
              state.social_facebook_id = x.channelSocialLinkId;
              state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }

            if (x.socialSite.socialSiteId == 2)
              state.tradeUrls = x.channelSocialLink
          })
        }

        state.tweetPitch = res.data.detailDesc ? res.data.detailDesc.replace(/<[^>]+>/g, '') : state.tweetPitch;
        state.companyDesc = res.data.companyDesc ? res.data.companyDesc.replace(/<[^>]+>/g, '') : state.companyDesc;
        state.companyDesc1 = res.data.companyDesc1 ? res.data.companyDesc1.replace(/<[^>]+>/g, '') : state.companyDesc1;
        state.ChannelProfileValue =
          (userData.channelTypeId == "4" || userData.channelTypeId == "5")
            ? res.data.profile ? res.data.profile[0] : null
            : res.data.profile;

        state.profileTypeId = +res.data.profileTypeId;

        setState({ ...state });
      }
    })
    setLoading(false);
  }

  const getChannelProduct = async () => {
    return await commonService
      .getServices("/setting/getChannelProduct")
      .then((res) => {
        if (res.data?.productGroup?.length && state.productFamilies?.length) {
          state.productFamilies = state.productFamilies.map(x => {
            res.data.productGroup.map(y => {
              if (x.groupid == y.groupid && x.childid == y.childid) {
                x.status = true;
              }
            })
            return x;
          })
        }

        state.productValue = res.data.productGroup;
        setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });
      })
      .catch((err) => {
        commonService.postService(
          {
            err: JSON.stringify(err),
            reqUrl: window.location.pathname,
            service: "/setting/getChannelProduct",
          },
          "/error"
        );
      });
  };

  const getCountry = async () => {
    await commonService
      .getServices("/common/getSignup/" + userData.channelTypeId)
      .then((res) => {
        state.countryList = res.data.country;
        setState({ ...state });
      })
      .catch((err) => {
        commonService.postService(
          {
            err: JSON.stringify(err),
            reqUrl: window.location.pathname,
            service: "/common/getSignup/",
          },
          "/error"
        );
      });
  };
  const getFactsDetails = async () => {
    await commonService
      .getServices("/setting/getChannelDetail")
      .then((res) => {
        // state.storeCount = res.data.storeCount;
        // state.no_of_skus=res.data.no_of_skus;
        state.domesticRevenue =
          (typeId == "4" || typeId == "5") ? res.data.turnoverOnStore : res.data.revD;
        state.internationalRev =
          (typeId == "4" || typeId == "5") ? res.data.onlineStore : res.data.revI;
        state.no_of_staffs = res.data.empCnt
          ? res.data.empCnt
          : res.data.empCntRange.replace(/\s/g, "").trim();

        if (!res.data.turnover && !res.data.turnoverRange && res.data.turnOverList?.length) {
          // state.annualTurnOver = res.data.turnOverList[0].turn_over
          res.data.turnOverList.sort((a, b) => b.year - a.year);
          // console.log("aturns:",res.data.turnOverList,res.data.turnOverList[0].year)
          state.annualTurnOver = res.data.turnOverList.filter(at => at.turn_over != '')[0].turn_over;
        }
        else
          state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange.replace(/\s/g, "").trim();
        state.areYouStartUp = res.data.startup;

        state.startUpType = startUp.map(x => {
          if (res.data.maturitylevel?.filter(y => y == x.value).length)
            return x;
        });

        state.startUpType = state.startUpType.filter(x => x);

        setState({ ...state });
      })
      .catch((err) => {
        commonService.postService(
          {
            err: JSON.stringify(err),
            reqUrl: window.location.pathname,
            service: "/setting/getChannelProduct",
          },
          "/error"
        );
      });
  };

  const getCompanyDetails = async () => {
    // setLoading(true);
    await commonService.getServices("/setting/company").then(async (res) => {


      if (res.data.endCustomerB2b?.length) {
        setEndCustomer((customer) => {
          return customer.map(x => {
            if (res.data.endCustomerB2b.map(ids => +ids.customerTypeId).includes(+x.id)) {
              x.selected = true;
              if (x.id == 8) {
                state.endCustomers = 8;
                state.otherEndCustomer = res.data.endCustomerB2b.find(b2b => b2b.customerTypeId == x.id).other;
              }
            }
            return x;
          })
        })
      }

      state.companyLogo = res.data.channel.channelDetail?.channelLogo?.documentPath;
      state.companyLogoUrl = res.data.channel.channelDetail.companyLogoUrl;
      state.companyName = res.data.channel.companyName;
      state.documentId = res.data.channel.channelDetail?.channelLogo ? res.data.channel.channelDetail?.channelLogo?.documentId : null;
      state.introVideo = res.data.channel.channelDetail.welcomevideo ? res.data.channel.channelDetail.welcomevideo?.documentId : null;
      if (res.data.channel.channelDetail.welcomevideo)
        setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl);


      state.legalAddress = res.data.channel.regAddress ? res.data.channel.regAddress?.address : '';
      state.regAddressId = res.data.channel.regAddressId;
      state.billAddressId = res.data.channel.billAddressId;
      state.zipCode = res.data.channel.regAddress ? res.data.channel.regAddress?.postalCode : '';
      state.city = res.data.channel.regAddress ? res.data.channel.regAddress?.city : '';
      state.country = {
        name: res.data.channel.country,
        id: res.data.channel.countryId,
      };
      state.phoneNumber1 = res.data.channel.channelDetail.phone1 ? res.data.channel.channelDetail.phone1 : "";
      state.tweetPitch = res.data?.channel?.channelDetail?.detailDesc ? res.data?.channel?.channelDetail?.detailDesc?.replace(/<[^>]+>/g, "") : '';
      state.awards = [];
      state.savedAwards = [];
      if (res.data.channel.channelAward) {
        res.data.channel.channelAward.map(x => {
          if (x.documentId) {
            x.documentUrl = process.env.REACT_APP_CDN_URL + x.awardImg;
            state.awards.push(x);
            state.savedAwards.push(x);
          }

        })
      }
      // if(state?.awards?.length>0){
      //   state.awards.map(x=> state.savedAwards.push({documentId:x.documentId, id:x.id}));
      // }


      state.sourcing = res.data.channel.channelDetail.expectations ? res.data.channel.channelDetail.expectations : '';
      state.companyDesc = res.data.channel.channelDetail.companyDesc ? res.data.channel.channelDetail.companyDesc : '';
      state.mobile = res.data.channel.channelDetail.mobileNo;
      state.isPhysicalSales = res.data.channel.channelDetail.isPhysicalSales;
      state.physicalSales = res.data.channel.channelDetail.physicalSales;



      // state.endCustomers = res.data.endCustomerB2b;
      // state.otherEndCustomer = res.data.endCustomerB2b?.other;
      if (res.data.channel.channelDetail.webSiteUrl)
        state.websiteUrl =
          res.data.channel.channelDetail.webSiteUrl.substring(0, 2) == "//"
            ? res.data.channel.channelDetail.webSiteUrl.substring(
              2,
              res.data?.channel?.channelDetail?.webSiteUrl?.length
            )
            : res.data.channel.channelDetail.webSiteUrl;
      state.email = res.data.channel.companyMail;
      state.isOEM = res.data.channel.isOEM;
      console.log('res', state)
      if (res.data.channel.channelCertificate) {
        res.data.channel.channelCertificate.map((x) => {
          if (x.cerificateTypeId == "1") {
            state.companyRegNumber = x.cerificateNumber;
            // state.certificateName = x.cerificateName;
            state.certificateId = x.channelCerificateId;
          }
          if (x.cerificateTypeId == "2") {
            state.vatNumber = x.cerificateNumber;
            state.vatNumberCheck = x.cerificateNumber;
            state.vat_verified = x.vat_verified;
            state.vatId = x.channelCerificateId;
          }
        });
      } else {
        await commonService.getServices('/setting/getVat/' + res.data.channel.countryId).then(res => {
          state.isVat = res.data.country[0].isVat;
          state.certificateName = res.data.country[0].registrationcertificate
        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getVat' }, '/error')
        })
      }

      if (res.data.channel?.channelSocial?.length) {
        res.data.channel.channelSocial.map((x) => {
          if (x.socialSite.socialSiteId == 1) {
            state.social_linkedin_id = x.channelSocialLinkId;
            state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 3) {
            state.social_instagram_id = x.channelSocialLinkId;
            state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }

          if (x.socialSite.socialSiteId == 2)
            state.tradeUrls = x.channelSocialLink;

          if (x.socialSite.socialSiteId == 4) {
            state.social_twitter_id = x.channelSocialLinkId;
            state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 5) {
            state.social_facebook_id = x.channelSocialLinkId;
            state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
        });
      }

      if (res.data?.channel?.channelB2B?.length) {
        state.b2bList = res.data.channel.channelB2B.map((x) => ({
          channel_B2B_id: x.channelB2BId,
          value: x.channelB2B,
        }));
      }
      else {
        state.b2bList = [{ id: Math.random(), value: '' }];
      }

      state.yearOfEstablishment = res.data.channel.channelDetail.estYear;
      state.profileTypeId = res.data.channel.profileTypeId;
      setState({ ...state });

      mediaState.productBrochures = [];
      if (res.data?.channelPdf?.length) {
        res.data.channelPdf.map((x) => {
          mediaState.productBrochures.push(x);
        });
      }
      mediaState.companyImages = [];
      if (res.data.channelImages?.length) {
        res.data.channelImages.map((x) => {
          mediaState.companyImages.push(x);
        });
      }


      if (res.data.linkedInBanner) {
        setImageSrc(res.data.linkedInBanner);
        setBannerId('dummy'); //we need this to use permanent cdn url
      }

      if (res.data.bannerImg?.documentPath) {
        setImageSrc(res.data.bannerImg?.documentPath);
        setBannerId(res.data.bannerImg?.documentId);
        mediaState.bannerImages = [
          {
            image: res.data.bannerImg?.documentPath,
            id: res.data.bannerImg?.documentId,
          },
        ];
      }
      setMediaState({ ...mediaState });
      //setState({ ...state });
    })
      .catch((err) => {
        console.log('err', err);
        commonService.postService(
          {
            err: JSON.stringify(err),
            reqUrl: window.location.pathname,
            service: "/setting/company",
          },
          "/error"
        );
      });

    // setLoading(false);

    await commonService
      .getServices("/setting/getInitialProfile")
      .then((res) => {
        if (userData?.channelTypeId === '4' || userData?.channelTypeId === '5') {
          let key = [];
          if (userData?.channelTypeId === '4')
            key = ["Retailer"];
          else
            key = ["Reseller"];

          console.log('key', key);
          state.ChannelProfileOption = res.data.profileList.filter((i) =>
            key.includes(i.groupname)
          );
        }
        state.productFamilies = res.data.productGroup;

        state.company_regid = res.data.country[0].registrationcertificate;
        state.isVat = res.data.country[0].isVat;
        if (props.ratingId || !state.certificateName) {
          state.certificateName = state.company_regid;
        }

        setState({ ...state });
      });
    //  setLoading(false);
  };


  const getChannelProfile = async () => {
    await commonService
      .getServices("/setting/getChannelProfile")
      .then((res) => {
        state.ChannelProfileValue =
          (userData.channelTypeId == "4" || userData.channelTypeId == "5")
            ? res.data.profile ? res.data.profile[0] : null
            : res.data.profile;
        setState({ ...state });
      })
      .catch((err) => {
        commonService.postService(
          {
            err: JSON.stringify(err),
            reqUrl: window.location.pathname,
            service: "/setting/getChannelProfile",
          },
          "/error"
        );
      });
  };
  const introVideoId = async (data) => {
    console.log('introVideoId', data.documentId);
    setInitialVideo('');
    setState({ ...state, introVideo: data.documentId });
    setLoading(true)
    let res = await commonService.postService({ id: data.documentId }, '/setting/updateIntroVideo')
      .catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateIntroVideo' }, '/error')
      });
    if (res) {
      ToastsStore.success('Intro video Saved Successfully!');
    }
    setLoading(false);
  }
  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      state[optionName].map((item) => {
        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map((option) => (option.status = true));
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else
    if ((optionValue = "productValue" && state.productValue?.length == 0))
      errors.productCat = true;
  };
  const getCompanyLogo = (resImagePath) => {
    setState({
      ...state,
      companyLogo: resImagePath.document.documentPath,
      documentId: resImagePath.documentId,
    });
  };
  const handleChangeSelect = async (input, value) => {
    if (input == "country") {
      setLoading(true);
      await commonService
        .getServices("/setting/getVat/" + value?.id)
        .then((res) => {
          state.isVat = res.data.country[0].isVat;
        })
        .catch((err) => {
          commonService.postService(
            {
              err: JSON.stringify(err),
              reqUrl: window.location.pathname,
              service: "/setting/getVat",
            },
            "/error"
          );
        });
      state[input] = value;
      setState({ ...state });
      setLoading(false);
    } else if (input == "productFamily") {
      state.productFamilies.map((item) => {
        if (value.length == 0) item.status = false;
        else {
          // value.map((v) => {
          //   if (v.childname == item.childname) item.status = true;
          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });
      value.map((option) => (option.status = true));
      state.productValue = value;
      setState({ ...state, productFamilies: state.productFamilies });
      setState({ ...state, productValue: state.productValue });
      if (state.productValue?.length == 0) errors.productValue = true;
      let Group = _.uniq(state.productValue.map((x) => x.groupid));
      state.groupCount = Group.length;
      setState({ ...state, groupCount: state.groupCount });
    } else if (input == "relevantChannelProfile") {
      state.ChannelProfileOption.map((item) => {
        if (value.length == 0) item.status = false;
        else {
          // value.map((v) => {
          //   if (v.childname == item.childname) item.status = true;
          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map((option) => (option.status = true));
      state.ChannelProfileValue = value;
      setState({ ...state });

      if (state.ChannelProfileValue.length == 0)
        errors.relevantChannelProfiles = true;
    } else if (input == "relevantRetailerProfile") {
      state.ChannelProfileValue = value;
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });
    }
    else if (input == 'startUpType') {
      errors.productBrand = null;
      setState({ ...state, [input]: value });
    }
    else if (input === 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state, sellingCountries: state.sellingCountries });

      if (state.sellingCountries.length === 0)
        errors.selling = true;
    }
    else {
      state[input] = value;
      setState({ ...state });
    }
  };

  const handleChangeEditor = value => {

    setState({ ...state, companyDesc: value, companyDesc1: value.replace(/<[^>]+>/g, '') });

  }

  const handleEndCustomer = (selectedValue) => {
    setEndCustomer((customer) => {
      return customer.map(x => {
        if (x.id == selectedValue) {
          x.selected = !x.selected;
          if (selectedValue == 8)
            setState({ ...state, endCustomers: x.selected ? selectedValue : null })
        }
        return x;
      })
    })
  }

  const handleChange = (input) => (e) => {
    if (input == "tradeUrls") {
      let b2b = [...state.b2bList];
      console.log("b2b", b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({ ...state, b2bList: b2b });
    }
    else if (input == "tweetPitch") {
      setState({ ...state, [input]: e.target.value.slice(0, 280) })
    }
    else if (input == "companyDesc") {
      setState({ ...state, [input]: e.target.value.slice(0, 2000) })
    }
    else if (input == "linkedIn" || input == "twitter" || input == "facebook" || input == "insta") {
      setState({ ...state, [input]: e.target.value.slice(state[input + '_pre'].length,) });
      errors[input] = false;
    }
    else if (input == 'annualTurnOver' || input == 'no_of_staffs') {
      let temp = input == 'annualTurnOver' ? 'turnOver' : 'staffCnt';
      document.getElementById(temp).value = null;
      errors[temp] = null;
      setState({ ...state, [input]: e.target.value });
    } else if (input == 'turnOver' || input == 'staffCnt') {
      let temp = input == 'turnOver' ? 'annualTurnOver' : 'no_of_staffs';
      setState({ ...state, [temp]: e.target.value });
    }
    else {
      setState({ ...state, [input]: e.target.value });
    }
    console.log("add", e.target.value, e.target);
  };
  const addNewB2B = () => {
    setState({
      ...state,
      b2bList: [...state.b2bList, { id: Math.random(), value: "" }],
    });
  };
  const delB2B = (id) => {
    console.log("id", id);
    let del = state.deleted_B2B;
    state.b2bList.map((x, i) => {
      if (id == i && x.channel_B2B_id) del.push(x.channel_B2B_id);
    });
    let temp = state.b2bList.filter((r, i) => i !== id);
    setState({ ...state, b2bList: temp, deleted_B2B: del });
  };
  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter((item) => {
      if (item.documentId == data.documentId && item.channelDocumentId) {
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({ ...state, deletedDocuments: temp });
      }
      return item.documentId != data.documentId;
    });
    if (filteredState) {
      setMediaState({ ...mediaState, productBrochures: filteredState });
      console.log("after delete", mediaState);
    }
  };
  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === "productBrochures") {
      console.log("brochures");
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(
        (file) =>
          !mediaState.productBrochures.find(
            (f) => f.documentId === file.documentId
          )
      );

      console.log("temp", temp, temp.length + newFiles.length);
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      } else {
        console.log(
          "Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb"
        );
      }
    } else if (input === "companyImages") {
      console.log("companyImages");
      let temp = mediaState.companyImages;
      let files = [];
      value.map((x) => {
        files.push({
          channelDocumentId: parseInt(x.channelDocumentId),
          channelId: x.channelId,
          documentUrl: x.document.documentUrl,
          documentPath: x.document.documentPath,
          documentId: x.documentId,
        });
      });
      let newFiles = files.filter(
        (file) =>
          !mediaState.companyImages.find(
            (f) => f.documentId === file.documentId
          )
      );

      console.log("temp", temp, temp.length + newFiles.length);
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      } else {
        console.log(
          "Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb"
        );
      }
      console.log("mediaState", mediaState);
    } else if (input === 'awardsImages') {
      console.log('awardsImages');
      let temp = state.awards;
      let files = [];
      value.map(x => {
        if (x?.channelDocumentId)
          files.push({ channelDocumentId: parseInt(x.channelDocumentId), channelId: x.channelId, documentUrl: x.document.documentUrl, documentId: x.documentId })
        else
          files.push({ id: parseInt(x.id), channelId: x.channelId, documentUrl: x.document.documentUrl, documentId: x.documentId })
      })
      let newFiles = files.filter(file => !state.awards.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      temp.push(...newFiles);
      if (state.deletedAwards?.length) {
        let docIds = [];
        temp.map(x => {
          if (x?.id)
            docIds.push(x.id);
        })
        console.log('docIds', docIds);
        state.deletedAwards = state.deletedAwards.filter(x => !docIds.includes(x));
      }
      setState({ ...state, [input]: temp });
      console.log('state', state);
      console.log('mediaState', mediaState);
    }
    else {
      let res = await handleOnDrop(input, value, filename);
      if (res) {
        console.log("res", res);
        setMediaState({ ...mediaState, [input]: value });
      }
    }
  };
  const editName = (item) => {
    docName = item.name;
    setDocName(docName);
    docId = item.documentId;
    setDocId(docId);
    setNamePopup(true);
  };
  const docNameChange = async (docId, name) => {
    console.log("media", mediaState.productBrochures);
    console.log(docId, name);
    mediaState.productBrochures.map((x) => {
      if (parseInt(x.documentId) === parseInt(docId)) x.name = name;
    });
    namePopupClose();
  };
  const namePopupClose = () => {
    setNamePopup(false);
  };
  //functions for image
  const handleOnDrop = async (input, data, file_name) => {
    if (data) {
      setLoading(true);
      console.log("datat", data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log("file", file);
        console.log("reader.result", reader.result);
        selectImg(input, reader.result, file_name);
        //setOpen(false);
      };
    }
  };
  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + ".png";
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append("file", file);
      let aws_folder = input === "bannerImages" ? "banner" : "";
      const resImagePath = await commonService.fileUploadService(
        formData,
        "/mediaupload/upload/" + aws_folder
      );
      if (resImagePath) {
        if (input === "bannerImages") {
          let temp = mediaState.bannerImages;
          temp.push({ image: resImagePath.data.url, filename: file_name });
          setMediaState({ ...mediaState, [input]: temp });
        }
        setLoading(false);
      } else {
        loading = false;
        setLoading(loading);
      }
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const handleChangeBanner = (event) => {
    const data = event.target.files[0];
    if (data && data["type"].split("/")[0] === "image") {
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
  };

  const selectBannerImg = async (data) => {
    if (data) {
      setImage("");
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + ".png";
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(
        formData,
        "/mediaupload/upload/" + "banner"
      );
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setBannerId("");
        setMediaState({
          ...mediaState,
          bannerImages: [{ image: resImagePath.data.url }],
        });
        setLoading(false);
      }
    }
  };
  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(
      (item) => item.filename != fileToBeRemoved
    );
    if (filteredState) {
      setMediaState({ ...mediaState, [input]: filteredState });
      console.log("after delete", mediaState);
    }
  };
  //Award
  const addAwards = (data) => {
    console.log('data', data);
    let index = state.awards.findIndex((obj => obj.id == data.id));
    if (index != -1) {
      if (state.awards[index].isOld)
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId, isOld: true });
      else
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId });
      setState({ ...state });
      console.log('updated', data);
    }
    else {
      let temp = state.awards;
      temp.push(Object.assign(data, { documentId: null }));
      setState({ ...state, awards: temp });
      console.log('added', data);
    }
  }

  const deleteAwards = (data) => {
    if (data?.id) {
      state.savedAwards.filter((item) => {
        if (data.id == item.id)
          state.deletedAwards.push(item.id);
      });
      const filteredList = state.awards.filter((item) => item.id !== data.id);
      state.awards = filteredList;
      setState({ ...state, awards: state.awards, deletedAwards: state.deletedAwards });
    } else {
      const filteredList = state.awards.filter((item) => item.documentId !== data.documentId);
      state.awards = filteredList;
      setState({ ...state, awards: state.awards });
    }
  }


  const [companyError, setCompanyError] = useState(false);
  useEffect(() => {
    setCompanyError(false);
  }, [watchAll.companyName]);

  const save = async () => {
    if (userData.roleId == 1 || (userData.roleId != 1 && (previlege == 1 || previlege == 0))) {
      try {
        setLoading(true);

        let checkData = {
          companyName: state.companyName
        }

        let checkname = await axios.post('setting/checkCompanyName', checkData).catch(err => {
          ToastsStore.warning('Something went wrong');
        });

        if (!checkname.data.isAvailable) {
          ToastsStore.warning('Company Name Already Taken.')
          setLoading(false);
          setCompanyError(true);
          return;
        }

        console.log("watchAll", watchAll);
        console.log("state", state);

        // let award_details=state.awards ? [].concat(state.awards.map(x => {
        //   if(x.isOld)
        //   {
        //     let isLifeTime = x.lifeTime==='1' || x.lifeTime==true ? '1' : '0';
        //     let valid_upto = x.date!=='1' ? x.date : null;
        //     if(!valid_upto && isLifeTime == '0')
        //       valid_upto = x.date;
        //     return {channel_award_id : x.id, details : x.award_details, issued_by : x.awardName, valid_upto : valid_upto, isLifeTime : x.lifeTime==='1' || x.lifeTime==true ? '1' : '0', image: x.awardImg ? x.awardImg : null, documentId: x.documentId ? x.documentId : null, }
        //   }
        //     return { details : x.award_details, issued_by : x.awardName, valid_upto : x.date!=='1' ? x.date : null, isLifeTime : x.lifeTime==='1' || x.lifeTime==true ? '1' : '0', image: x.awardImg ? x.awardImg : null }
        // })) : [{}];

        // let deleted_award_details = state.deletedAwards.length?state.deletedAwards:[null];

        let awardDocumentIds = [];
        console.log('savedAwards', state.savedAwards);

        if (state.savedAwards?.length)
          state.savedAwards.map(x => awardDocumentIds.push(x.documentId));

        let award_details = state.awards.filter(x => !awardDocumentIds.includes(x.documentId));
        let deleted_award_details = state?.deletedAwards?.length > 0 ? state.deletedAwards : [];

        // trade
        let customerProfiles =
          (typeId === "4" || typeId === "5")
            ? [
              {
                profileTypeId: +state.ChannelProfileValue.childid,
                profileTypeClassificationId: +state.ChannelProfileValue
                  .profiletypeclassificationid,
              },
            ]
            : [];
        let productFamilies = [];
        let uniq = _.uniq(state.productValue.map((x) => +x.groupid));
        uniq.map((x) => {
          productFamilies.push({ id: +x });
        });
        let productCategories = [];
        state.productValue.map((x) => {
          productCategories.push({
            productFamilId: +x.groupid,
            productCategoriesId: +x.childid,
          });
        });

        let tax_cert = [];
        let reg_cert = [];
        reg_cert.push({
          name: state.certificateName,
          id: state.companyRegNumber,
          channel_certificate_id: parseInt(state.certificateId),
        });
        if (state.isVat == "1") {

          // if (state.vatNumber != state.vatNumberCheck || state.vat_verified != '1') {

          //   await axios.post('/setting/checkVat', { vatNumber: state.vatNumber }).then(res => {
          //     state.vat_verified = res.data.valid;
          //   }).catch(err => console.log('checkvat error', err));
          // }

          if (!state.vatNumber)
            state.vat_verified = '0';

          tax_cert.push({ name: 'VAT', id: state.vatNumber, channel_certificate_id: parseInt(state.vatId), vat_verified: state.vat_verified == '1' ? '1' : '0' });
        }
        let certificates = {
          companyRegNumber: state.companyRegNumber,
          certificateName: state.certificateName,
          vatNumber: state.isVat == '1' ? state.vatNumber : null,
          registration_certificate: reg_cert,
          vat_verified: state.vat_verified == '1' ? '1' : '0'
        }

        if (tax_cert.length)
          certificates = Object.assign(certificates, { tax_certificate: tax_cert });

        let data = {
          company_logo_url: state.documentId,
          company_name: state.companyName,
          company_url: state.companyLogo
            ? process.env.REACT_APP_CDN_URL +
            state.companyLogo
            : process.env.REACT_APP_CDN_URL +
            "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg",
          is_same_address: 0,
          reg_address_id: +state.regAddressId,
          reg_country_id: +state.country.id,
          reg_address: state.legalAddress,
          reg_postal_code: state.zipCode,
          reg_city: state.city,
          certificates: certificates,
          company_phone1: state.phoneNumber1 + "",
          profileTypeId: +state.ChannelProfileValue.childid,
        };
        if (tax_cert?.length)
          data = Object.assign(data, { tax_certificate: tax_cert });

        let bannerImage = mediaState?.bannerImages?.length ? [].concat(mediaState.bannerImages.map(x => {
          return { image: x.image, id: x.id ? x.id : '' }
        })) : [];

        let marketingInfo = {
          company_mobile_no: state.mobile,
          company_email: state.email,
          company_website_url: state.websiteUrl,
          social_linkedin_url: state.linkedIn,
          social_instagram_url: state.insta,
          social_linkedin_id: state.social_linkedin_id,
          social_googleplus_id: null,
          social_instagram_id: state.social_instagram_id,
          contact_linkedin_id: null,
          social_twitter_url: state.twitter,
          social_twitter_id: state.social_twitter_id,
          social_facebook_url: state.facebook,
          social_facebook_id: state.social_facebook_id,
          deleted_B2B: state.deleted_B2B?.length > 0 ? state.deleted_B2B : [],
          company_introvideo: state.introVideo,
          award_details: award_details,
          bannerImage: bannerImage,
          deleted_award_details: deleted_award_details,
          companyImages: mediaState.companyImages,
          deleted_companyImages: state.deleted_companyImages,
        }
        if (marketingInfo?.companyImages?.length === 0)
          delete marketingInfo.companyImages

        if (marketingInfo?.deleted_companyImages?.length === 0)
          delete marketingInfo.deleted_companyImages

        if (state?.b2bList?.length) {
          state.b2bList = [].concat(state.b2bList.map(x => {

            if (x.channel_B2B_id && x.value)
              return { B2B_url: x.value, channel_B2B_id: x.channel_B2B_id }
            else if (x.channel_B2B_id && !x.value) {
              state.deleted_B2B.push(x.channel_B2B_id);
              return null;
            } else if (x.value && !x.channel_B2B_id) {
              return { B2B_url: x.value }
            }

          }));
          state.b2bList = state.b2bList.filter((el) => el != null);
          marketingInfo = Object.assign(marketingInfo, { deleted_B2B: state?.deleted_B2B?.length ? [... new Set(state.deleted_B2B)] : [] });
          if (state?.b2bList?.length)
            marketingInfo = Object.assign(marketingInfo, { B2B_url: state.b2bList });
        }
        let channels = {
          customerProfiles: customerProfiles,
        }
        if (state.endCustomers == 8) {
          setEndCustomer((customer) => {
            return customer.map(x => {
              if (x.id == 8) {
                x.name = state.otherEndCustomer;
              }
              return x;
            })
          })
        }

        let endcus = [];
        endCustomerOption.map(x => {
          if (x.selected == true) {
            let cust = {
              channelId: +userData?.channelId,
              customerTypeId: +x.id,
              other: x.id == 8 ? state.otherEndCustomer : null
            }
            endcus.push(cust);
          }
        })

        let tradeInfo = {
          company_about: state.tweetPitch,
          company_expectation: state.sourcing ? state.sourcing : null,
          companyDesc: state.companyDesc ? state.companyDesc : null,
          productFamilies: productFamilies,
          productCategories: productCategories,
          isPhysicalSales: state.isPhysicalSales,
          physicalSales: state.physicalSales,
          endCustomers: endcus,//endCustomerOption.filter(x => x.selected == true),//state.endCustomers,
          otherEndCustomer: state.otherEndCustomer
        }

        //Maturity level
        let mLevel = [];
        if (state.areYouStartUp == '1') {
          mLevel = state.startUpType.map(x => x.value);
        }

        let facts = {
          physicalStore: state.domesticRevenue ? +state.domesticRevenue : null,
          OnlineStore: state.internationalRev ? +state.internationalRev : null,
          // storeCount: +state.storeCount,
          year_of_estabilishment: +state.yearOfEstablishment,
          startup: state.areYouStartUp,
          maturitylevel: state.areYouStartUp == "1" ? mLevel : [],
        }

        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.no_of_staffs))
          facts["employeesRange"] = state.no_of_staffs;
        else facts["employeesCount"] = +state.no_of_staffs;

        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.annualTurnOver))
          facts["annualTurnoverRange"] = state.annualTurnOver;
        else facts["annualTurnover"] = +state.annualTurnOver;

        let productBrochure = {
          channel_document: mediaState?.productBrochures?.length
            ? [].concat(
              mediaState.productBrochures.map((x) => {
                return {
                  channelDocumentId: x.channelDocumentId
                    ? +x.channelDocumentId
                    : null,
                  documentId: +x.documentId,
                  document: x.document.documentUrl,
                  documentName: x.name,
                };
              })
            )
            : [{ document: "null" }],
        };

        //Trade Location
        let sellingLocation = [];
        sellingLocation = state.sellingCountries.map(x => +x.regionCountryJCTId);
        let loc = {
          sellingLocation: sellingLocation,
        }
        let locations = [];

        if (typeId == "4")
          locations.push({
            locationType: 'RETAIL',
            isUpdate: true,
            location: loc.sellingLocation,
          });

        if (typeId == "5")
          locations.push({
            locationType: 'RESELLING',
            isUpdate: true,
            location: loc.sellingLocation,
          });

        //setting documents
        let document = {
          channel_document: productBrochure.channel_document,
          company_introvideo: state.introVideo,
        }

        if (state.deletedDocuments?.length)
          document = Object.assign(document, { deleted_channel_documents: state.deletedDocuments });


        let reqData = {
          companyRegistration: data, channels: channels, tradeInformation: tradeInfo, marketing: marketingInfo, facts: facts, document: document, ratingId: props.ratingId ? props.ratingId : null, locations: locations,
          annualTurnOverList: state.annualTurnOverList
        }
        let res = await commonService
          .postService(reqData, "/setting/update_company_profile")
          .catch((err) => {
            ToastsStore.error("Something Went Wrong!");
            commonService.postService(
              {
                err: JSON.stringify(err),
                reqUrl: window.location.pathname,
                service: "/setting/update_company_profile",
              },
              "/error"
            );
          });
        if (res) {
          ToastsStore.success("Saved Successfuly...");

          if (props.ratingId) {
            history.push('/admin/dashboard');
          }

          await getCompanyDetails();
          await getChannelProduct();
          await getChannelProfile();
          await getFactsDetails();

        }
        setLoading(false);
      } catch (err) {
        console.log("err", err);
        ToastsStore.error("Something Went Wrong!");
        setLoading(false);
        commonService.postService(
          {
            err: JSON.stringify(err),
            reqUrl: window.location.pathname,
            service: "updateCompany",
          },
          "/error"
        );
      }
    }
    else {
      modalPopValue.message = 'You are restricted to Modify and Save Company Info';
      modalPopValue.second_message = 'PLEASE CONTACT ACCOUNT OWNER'
      modalPopValue.title = 'Access Denied';
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }

  };
  const deleteImg = (e) => {
    console.log("e", e);
    // error.productPos=null;
    mediaState.companyImages = mediaState.companyImages.filter(
      (x) => x.documentId !== e.documentId
    );
    if (e?.companyMediaId)
      state.deleted_companyImages.push(parseInt(e.companyMediaId));

    setMediaState({ ...mediaState });
    setState({ ...state });
    // setError({...error});
  };
  const openMediaLib = () => {
    console.log("mediaState", mediaState);
    if (mediaState.companyImages?.length >= 5) {
      ToastsStore.error("Maximum 5 Images");
    } else {
      setComImage(true);
    }
  }
  const openAwardMediaLib = () => {
    if (state.awards.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setAwardImage(true);
    }
  }

  const setTradelocation = async () => {
    try {
      let target = []; let selling = [];
      //setLoading(true);

      await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {

        state.sellingCountriesOption = res.data.regions1;
        selling = res.data.regions1;
        state.targetCountriesOption = res.data.regions;
        target = res.data.regions;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })

      await axios.get('setting/getChannelLocation').then(res => {
        target = target.map(x => {
          res.data.targetArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });

        selling = selling.map(x => {
          res.data.sellingArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });
        state.targetCountriesOption = target;
        state.targetCountries = res.data.targetArray.length === 0 ? [] : res.data.targetArray;
        state.sellingCountries = res.data.sellingArray.length === 0 ? [] : res.data.sellingArray;
        state.sellingCountriesOption = selling
        setState({ ...state });
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactLocation' }, '/error');
      });
    }
    catch (err) {
      //ToastsStore.error('User Location Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setTradelocation' }, '/error');
    }
  }

  const closePopup = () => {
    setModalPopup(false);
  };

  const confirmPopup = (res) => {
    setModalPopup(false);
  }

  const closeTurnOver = () => {
    setAnnualTurn(false);
  };

  const saveTurnOver = async (value) => {
    setAnnualTurn(false);
    state.annualTurnOverList = value;
    setState({ ...state });
    console.log("saveproduct:", value);

  };

  const handleValidation = (e) => {
    const charCode = e.which || e.keyCode;
    if (e.target.value.length == 30) {
      e.preventDefault();
    }

    if (e.target.value === '' && charCode === 48) {
      e.preventDefault(); // Prevent input
    }

    if (
      (charCode >= 48 && charCode <= 57) ||
      charCode === 43 || // Plus (+)
      charCode === 45 || // Minus (-)
      charCode === 8 ||  // Backspace
      charCode === 46    // Delete
    ) {
    } else {
      e.preventDefault();
    }
  }

  return (
    <>
      {!props.ratingId && <SimpleHeader location={props.match} />}
      <ToastsContainer
        position="top_right"
        autoClose={5000}
        store={ToastsStore}
      />
      {isAnnualTurn &&
        <AnnualTurnOver open={isAnnualTurn} currentValue={state.annualTurnOverList} handleClose={closeTurnOver} handleSave={saveTurnOver} />}

      <DocumentName
        isOpen={namePopup}
        handleClose={namePopupClose}
        isEdit={true}
        handleSave={docNameChange}
        name={docName}
        docId={docId}
      />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      {loading ? (
        <Spinner />
      ) : (
        <Container className="mt-4 col-xl-10 col-lg-12 col-md-12 col-sm-12 retailReseller " fluid>
          <form noValidate onSubmit={handleSubmit(save)}>
            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
            {comImage && (
              <MultiSelectImages
                id={1}
                isOpen={comImage}
                multiple={true}
                limit={5}
                acceptedFiles={["image/jpeg", "image/png", "image/gif"]}
                getBrochuresIds={handleChangeFileSelect}
                input="companyImages"
                aws_folderName="companyImages"
                handleClose={() => { setComImage(false) }}
                documentIds={
                  mediaState?.companyImages?.length > 0
                    ? mediaState.companyImages.map((x) => parseInt(x.documentId))
                    : []
                }
              />
            )}
            {awardImage &&
              <MultiSelectImages
                id={2}
                isOpen={awardImage}
                multiple={true}
                limit={5}
                acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
                getBrochuresIds={handleChangeFileSelect}
                input="awardsImages" aws_folderName="awards"
                handleClose={() => {
                  setAwardImage(false)
                }}
                documentIds={state.awards.length > 0 ? state.awards.map(x => parseInt(x.documentId)) : []}
              />
            }
            <Card>
              <CardHeader className="col-lg-12 justify-content-center">
                <h3 className="text-center">Company Profile</h3>
                {/* <p className="text-center">Enter Company legal details.</p> */}
              </CardHeader>
              <CardBody className="retailreseller">
                <div className="row">
                  <div className="col-sm-12  col-lg-12">
                    <div className="row">
                      <div className="col-sm-5 col-12 col-md-6 pl-0 col-lg-3">
                        <input
                          type="text"
                          style={{
                            position: "absolute",
                            left: "-10000px",
                            top: "auto",
                            width: "1px",
                            height: "1px",
                            overflow: "hidden",
                          }}
                          id="companyLogo"
                          name="companyLogo"
                          ref={register}
                          value={state.companyLogo}
                        />
                        <h4>Company Logo *</h4>
                        {!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Company Logo</span>}

                        <div>
                          <ImageLibrary
                            getDocumentId={getCompanyLogo}
                            aws_folderName="CompanyLogo"
                            id={1}
                            size={1}
                            img={state.companyLogo ? process.env.REACT_APP_CDN_URL + state.companyLogo : state.companyLogoUrl ? process.env.REACT_APP_CDN_URL_TEMP + state.companyLogoUrl : process.env.REACT_APP_CDN_URL +
                              "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                          />
                        </div>
                      </div>
                      <div className="col-sm-7 col-12 col-md-5 col-lg-3 mt-5">
                        <span>Accepts Jpeg / Jpg / Png Only</span>
                        <br></br>
                        <span>(Max size allowed 200 Kb)</span>
                        <span className="d-block">(* Image resolution should be 200 * 200)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mt-2">
                    <FormInput
                      autoFocus
                      className="comp-name mt-2"
                      id="companyName"
                      name="companyName"
                      label="Company Legal Name *"
                      type="text"
                      variant="outlined"
                      // value={state.companyName}
                      defaultValue={state.companyName}
                      error={companyError ? { message: "Company Name already taken", required: true } : errors.companyName}
                      register={register}
                      value={watchAll.companyName}
                      onChange={handleChange('companyName')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mt-3">
                    <FormInput className="comp-name mt-2 addrs" id="legalAddress" name="legalAddress" label="Registered/Legal Address *" type="text" variant="outlined" value={state.legalAddress} error={errors.legalAddress}
                      onChange={handleChange('legalAddress')} register={register}
                    />
                  </div>
                  <div className="col-lg-6 mt-4 ">
                    <div className="row">
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <FormAutocomplete
                          id="country"
                          name="country"
                          label="Country *"
                          options={state.countryList}
                          placeholder="Select Country"
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          getOptionSelected={(options, value) => options.name === value.name}
                          input="country"
                          value={state.country}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={"Please Select Country"}
                          error={errors.country}
                          defaultValue={state.country}
                        />
                      </FormControl>
                    </div>
                    <div className="row">
                      <FormInput
                        className="comp-name mt-4"
                        id="zipCode"
                        name="zipCode"
                        placeholder=""
                        label="Zip/Pincode *"
                        type="text"
                        variant="outlined"
                        value={watchAll.zipCode}
                        defaultValue={state.zipCode}
                        register={register}
                        error={errors.zipCode}
                        onChange={handleChange('zipCode')}
                      />
                    </div>
                    <div className="row">
                      <FormInput
                        className="comp-name mt-4"
                        id="city"
                        name="city"
                        label="City *"
                        type="text"
                        variant="outlined"
                        value={watchAll.city}
                        defaultValue={state.city}
                        error={errors.city}
                        register={register}
                        onChange={handleChange('city')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 mt-3">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor=""></label>
                      <InputGroup className="" >
                        <InputGroupAddon style={{ width: '30%' }} addonType="prepend">
                          <InputGroupText id="certificateName" className="justify-content-center ellipsisText" style={{ width: '100%' }}> {state.certificateName}</InputGroupText>
                        </InputGroupAddon>
                        <UncontrolledTooltip target="certificateName" placement='bottom'>
                          {state.certificateName}
                        </UncontrolledTooltip>
                        <FormInput style={{ width: '70%' }} className=""
                          id="CRN"
                          type="text"
                          name="CRN"
                          label="Company registration number"
                          register={register}
                          error={errors.CRN}
                          defaultValue={state.companyRegNumber}
                          value={watchAll.CRN}

                          onChange={handleChange("companyRegNumber")}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <div className="form-group">
                      <label className="form-control-label" htmlFor="">
                        <input
                          type="text"
                          style={{
                            position: "absolute",
                            left: "-10000px",
                            top: "auto",
                            width: "1px",
                            height: "1px",
                            overflow: "hidden",
                          }}
                          id="isVat"
                          ref={register}
                          name="isVat"
                          value={state.isVat}
                          control={control}
                        />
                      </label>
                      {/* {state.isVat == "1" && (
                        <InputGroup className="">
                          <InputGroupAddon style={{ width: '20%' }} addonType="prepend">
                            <InputGroupText id="Vat" className="justify-content-center" style={{ width: '100%' }}>VAT</InputGroupText>
                          </InputGroupAddon>
                          <FormInput style={{ width: '80%' }}
                            id="VATN"
                            type="text"
                            name="VATN"
                            label="VAT number"
                            placeholder="Only for EU companies"
                            register={register}
                            error={errors.VATN}
                            defaultValue={state.vatNumber}
                            value={watchAll.VATN}
                            onChange={handleChange("vatNumber")}
                          />
                          {state.vat_verified == 1 && state.vatNumber && 
                            <div className="Checkmark">
                              <span><i className="fas fa-check text-success Checkspan"></i></span>
                            </div>}

                          {state.vat_verified == 0 && state.vatNumber && 
                            <div className="Checkmark">
                              <span><i className="fas fa-times text-danger Checkspan"></i></span>
                            </div>}
                        </InputGroup>
                      )} */}
                    </div>
                  </div>
                </div>
                <Row>
                  <Col lg="6" className="code">
                    <div className="form-group">
                      <FormInput
                        className="comp-name1"
                        id="phoneNumber1"
                        name="phoneNumber1"
                        label="Switchboard Number"
                        type="text"
                        variant="outlined"
                        value={watchAll.phoneNumber1}
                        onKeyPress={handleValidation}
                        onChange={handleChange("phoneNumber1")}
                        onPaste={(e) => e.preventDefault()}
                        defaultValue={state.phoneNumber1}
                        error={errors.phoneNumber1}
                        register={register}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="retailResellerTrade">
              <CardHeader className="col-lg-12 justify-content-center">
                <h3 className="text-center">Trade Information</h3>
              </CardHeader>
              <CardBody>
                {(typeId == 4 || typeId == 5) && (
                  <div className="col-lg-12 ">
                    <h3 style={{ fontSize: "1rem" }}>Your profile *</h3>
                    {/* <h4>Retailer Profiles *</h4> */}
                    <div className="form-group">
                      <SingleAutocompleteGrouping
                        id="relevantProfiles"
                        name="relevantRetailerProfiles"
                        label={typeId == 4 ? "Retailer Profiles * " : "Reseller Profiles * "}
                        multiple={false}
                        Options={state.ChannelProfileOption}
                        placeholder="Channel Profiles"
                        input="relevantRetailerProfile"
                        errMessage={typeId == 4 ? "Please Select Retailer Profiles" : "Please Select Reseller Profiles"}
                        error={errors.relevantRetailerProfiles}
                        value={state.ChannelProfileValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                        defaultValue={state.ChannelProfileValue}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12 mt-4 ">
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="tweetPitch"
                      name="tweetPitch"
                      ref={register}
                      value={state.tweetPitch}
                    />
                    {!errors.tweetPitch ||
                      (state.tweetPitch?.length > 5 &&
                        state.tweetPitch?.length < 280) ? (
                      <h3 style={{ fontSize: "1rem" }}>
                        Your company’s short presentation Pitch (280 characters) *
                        {state.tweetPitch?.length > 0 && <span style={{ color: state.tweetPitch?.length > 280 ? "red" : "#9ea2a5" }}> {state.tweetPitch?.length > 280 ? 'description is too long, maximum size is 280 characters' : `remaining characters  ${280 - state.tweetPitch.length}`}</span>}
                      </h3>
                    ) : (
                      <h4>
                        Your Company - Tweet Pitch *{" "}
                        <span
                          style={{
                            color: "#f44336",
                            float: "right",
                            fontSize: "0.75rem",
                          }}
                        >
                          {(!state.tweetPitch || state.tweetPitch === '')
                            ? "Tweet Pitch can't be empty."
                            : state.tweetPitch?.length <= 5
                              ? "Tweet Pitch must have min 5 characters"
                              : state.tweetPitch?.length > 280
                                ? "Tweet Pitch must have max 280 characters"
                                : ""}
                        </span>
                      </h4>
                    )}
                    <div className="form-group">
                      <FormTextArea
                        className="buytext2 w-100 h-auto"
                        name="note"
                        id="note"
                        rows="3"
                        type="textarea"
                        label=" "
                        placeholder="Tell us about your company background and vision -max 280 characters *"
                        value={state.tweetPitch}
                        onChange={handleChange("tweetPitch")}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-12 mt-4 ">
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="sourcing"
                      name="sourcing"
                      ref={register}
                      value={state.sourcing}
                    />
                    {!errors?.sourcing ||
                    (state.sourcing?.length >= 5 &&
                      state.sourcing?.length <= 2000) ? (
                      <h3 style={{ fontSize: "1rem" }}>
                        Your company’s long presentation (2000 characters) 
                      </h3>
                    ) : (
                      <h4>
                        Sourcing Priority & Strategy{" "}
                        <span
                          style={{
                            color: "#f44336",
                            float: "right",
                            fontSize: "0.75rem",
                          }}
                        >
                            {(!state.sourcing||state.sourcing==='')
                            ? "Sourcing Priority & Strategy can't be empty."
                            : state.sourcing?.length <= 5
                            ? "Sourcing Priority & Strategy must have min 5 characters"
                            : state.sourcing?.length > 280
                            ? "Sourcing Priority & Strategy must have max 2000 characters"
                            : ""}
                        </span>
                      </h4>
                    )}
                    <div className="form-group">
                      <FormTextArea
                        className="buytext2 w-100"
                        placeholder="Tell us about your company background and vision -max 2000 characters *"
                        onChange={handleChange("sourcing")}
                        value={state.sourcing}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-sm-12 mt-4 ">
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="companyDesc"
                      name="companyDesc"
                      ref={register}
                      value={state.companyDesc}
                    />
                    {!errors?.companyDesc ||
                      (state.companyDesc?.length >= 5 &&
                        state.companyDesc?.length <= 2000) ? (
                      <h3 style={{ fontSize: "1rem" }}>
                        Your company's full description (2000 characters)
                        {state.companyDesc?.length > 0 && <span style={{ color: state.companyDesc?.length > 2000 ? "red" : "#9ea2a5" }}> remaining characters  {2000 - state.companyDesc.length}</span>}
                      </h3>
                    ) : (
                      <h4>
                        Company’s Description{" "}
                        <span
                          style={{
                            color: "#f44336",
                            float: "right",
                            fontSize: "0.75rem",
                          }}
                        >
                          {(!state.companyDesc || state.companyDesc === '')
                            ? "Company Description can't be empty."
                            : state.companyDesc?.length <= 5
                              ? "Company Description must have min 5 characters"
                              : state.companyDesc?.length > 2000
                                ? "Company Description must have max 2000 characters"
                                : ""}
                        </span>
                      </h4>
                    )}
                    <div className="form-group">
                      {/* <FormTextArea
                        className="buytext2 w-100"
                        placeholder="Tell us about your company description and vision -max 2000 characters *"
                        onChange={handleChange("companyDesc")}
                        value={state.companyDesc}
                      /> */}
                      <ReactQuill
                        theme="snow"
                        placeholder="Tell us about your company description and vision -max 2000 characters *"
                        onChange={handleChangeEditor}
                        value={state.companyDesc}
                        // ref={register}
                        id="companyDesc"
                        name="companyDesc"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline"],
                            [
                              {
                                list: "ordered"
                              },
                              {
                                list: "bullet"
                              }
                            ]
                          ]
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <h4>Your Product categories *</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="productCat"
                      name="productCat"
                      ref={register}
                      value={
                        state.productValue?.length
                          ? state.productValue[0]?.childname
                          : ""
                      }
                    />
                    <FormAutocompleteGrouping
                      id="ProductGroup"
                      name="ProductGroup"
                      label="Product categories are you selling"
                      Options={state.productFamilies}
                      placeholder="Select Product categories"
                      input="productFamily"
                      errMessage={"Please Select a Product"}
                      error={
                        !errors.productCat || state.productValue?.length
                          ? false
                          : true
                      }
                      //error={errors.ProductGroup}
                      //defaultValue={productFamilies}
                      value={state.productValue}
                      optionName="productFamilies"
                      optionValue="productValue"
                      defaultValue={state.productValue}
                      selectedItem={selectedItem}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12  row ">
                    <div className="form-group text-center text-sm mb-2">
                      <h4>Are you looking for start-ups? <span id='algorithm'><i class="far fa-handshake didy"></i></span><UncontrolledTooltip className="didy2 handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                      <FormRadio
                        name="startup"
                        className="text-sm mb-0"

                        options={radioGroupOptions2}
                        control={control}
                        row
                        defaultValue={state.areYouStartUp}
                        value={state.areYouStartUp}
                        onChange={handleChange('areYouStartUp')}
                      />
                    </div></div>

                  {(state.areYouStartUp == '1') && <div className="col-lg-4 mt-2">
                    <h4>What's the maturity level you would accept? * </h4>

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productBrand" ref={register} name="productBrand" value={state.startUpType[0] ? 'data' : ''} control={control} />

                    <FormAutocomplete
                      id="maturity"
                      name="maturity"
                      label="Maturity Level"
                      options={startUp}
                      placeholder="Select Maturity Level"
                      multiple
                      disableCloseOnSelect
                      BlurOnSelect
                      limitTags={1}
                      getOptionSelected={(options, value) => options.name === value.name}
                      defaultValue={state.startUpType}
                      control={control}
                      input='startUpType'
                      value={state.startUpType}
                      register={register}
                      errMessage={'Please Select Maturity Level'}
                      error={errors.productBrand}
                      handleChangeSelect={handleChangeSelect}

                    />

                  </div>}
                </div> */}


                {(state.isOEM === '1' || state.isOEM === '3') && <Row>
                  <Col lg='3'>
                    <div className="form-group text-left text-sm mb-0">
                      <h4>Do you have physical point of sales? </h4>
                      <FormRadio
                        name="isPhysicalSales"
                        className="text-sm mb-0"
                        options={radioGroupOptions}
                        control={control}
                        row
                        value={state.isPhysicalSales}
                        onChange={handleChange('isPhysicalSales')}
                      />
                    </div>
                  </Col>
                  {state.isPhysicalSales === '1' && <Col lg='2'>
                    <div className="form-group mt-4 mb-0">
                      <FormInput
                        id="physicalSales"
                        type="number"
                        name="physicalSales"
                        label="how many"
                        register={register}
                        error={errors.physicalSales}
                        defaultValue={state.physicalSales}
                        value={watchAll.physicalSales}
                        onChange={handleChange('physicalSales')}
                      />
                    </div>
                  </Col>}
                </Row>}

                {((state.isOEM === '2' || state.isOEM === '3') && typeId === "5") && <Row>
                  <Col lg='4'>
                    <div className="form-group text-left text-sm mb-0">
                      <h4>Profile of your end-customers?  </h4>
                      {/* <FormRadio
                    name="endCustomers"
                    className="text-sm mb-0"
                    options={radioGroupOptions1}
                    control={control}
                    // row
                    value={state.endCustomers} 
                    onChange={handleChange('endCustomers')}
                     /> */}
                      {endCustomerOption.map(x =>
                        <div className="chck">
                          <FormCheckBox
                            id={x.id}
                            type="checkbox"
                            label={x.name}
                            checked={x.selected}
                            label1={{ textAlign: "justify" }}
                            onClick={(e) => handleEndCustomer(x.id)}
                          //register={register
                          />
                        </div>)}
                    </div>
                    {state.endCustomers === '8' && <div className="form-group mt-4 mb-0">
                      <FormInput
                        id="otherEndCustomer"
                        type="text"
                        name="otherEndCustomer"
                        label="other"
                        register={register}
                        error={errors.otherEndCustomer}
                        defaultValue={state.otherEndCustomer}
                        value={watchAll.otherEndCustomer}
                        onChange={handleChange('otherEndCustomer')}
                      />
                    </div>}
                  </Col>
                </Row>}
                {/* <Row>
                {(typeId == "4"||typeId == "5") && (
                      <div className="col-lg-4 mt-4">
                        <h4>No. of Stores</h4>
                        <FormInput
                          id="storeCount"
                          type="number"
                          name="storeCount"
                          label={"No of Store's *"}
                          InputProps={{
                            inputProps: {
                              max: 100,
                              min: 10,
                            },
                          }}
                          //value={+state.no_of_skus}
                          onChange={handleChange("storeCount")}
                          register={register}
                          defaultValue={state.storeCount}
                          value={watchAll.storeCount}
                          error={errors.storeCount}
                        />
                      </div>
                    )}
                </Row> */}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h3 className="text-center" >Countries of operation<span id='algorithm'><i class="far fa-handshake didy"></i></span>
                  <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                <p className="text-center">Update the list of countries where you are currently operating.</p>
              </CardHeader>
              <CardBody>
                <div className="col-lg-12 ">
                  <div className="form-group">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                    <FormAutocompleteGroupingRegion
                      input="sellingCountries"
                      id="SellingCountries"
                      label='Selling Locations *'
                      name="SellingCountries"
                      Options={state.sellingCountriesOption}
                      placeholder="Search"
                      errMessage='Please choose Selling Locations'
                      error={!errors.selling || state.sellingCountries.length ? false : true}
                      optionName='sellingCountriesOption'
                      optionValue='sellingCountries'
                      defaultValue={state.sellingCountries}
                      value={state.sellingCountries}
                      // loading={true}
                      selectedItem={selectedItem}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                      isGroup='0'
                    />
                  </div>
                </div>
              </CardBody>
            </Card>


            <Card>
              <CardHeader className="col-lg-12 justify-content-center">
                <h3 className="text-center">Marketing information</h3>
              </CardHeader>
              <CardBody>

                <Row>
                  <Col lg="12">
                    <FormInput className="comp-name mt-2" id="outlined-search" name="websiteUrl" label="Website URL *" type="text" variant="outlined" value={watchAll.websiteUrl}
                      defaultValue={state.websiteUrl}
                      register={register}
                      error={errors.websiteUrl}
                      onChange={handleChange('websiteUrl')}
                    />
                  </Col>
                </Row>

                {state.b2bList &&
                  state.b2bList.map((x, i) => (
                    <Row>
                      <Col lg="11">
                        <FormInput
                          className="comp-name mt-4"
                          id={i}
                          name={`tradeUrls[${i}].value`}
                          // label="Other B2B or Trade Portals Website URLs"
                          label="Website URLs"
                          type="text"
                          variant="outlined"
                          value={watchAll[`tradeUrls[${i}].value`]}
                          onChange={handleChange("tradeUrls")}
                          register={register}
                          error={
                            errors.tradeUrls
                              ? errors.tradeUrls[i]
                                ? errors.tradeUrls[i].value
                                : ""
                              : ""
                          }
                          defaultValue={x.value}
                        />
                      </Col>
                      {i == 0 ? (
                        <Col lg="1">
                          <div className="faplus">
                            <i
                              class="fa fa-plus-circle"
                              onClick={addNewB2B}
                            ></i>
                          </div>
                        </Col>
                      ) : (
                        <Col lg="1">
                          <div className="faminus">
                            <i
                              class="fa fa-minus-circle "
                              onClick={() => delB2B(i)}
                            ></i>
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                {/* <Row>
    <Col lg="6"  className="code mt-4">
    <div className="form-group">
    <FormInput className="comp-name1" id="mobile" name="mobile"  label="mobile" type="text" variant="outlined" value={watchAll.mobile}
    onChange={handleChange('mobile')}
    defaultValue={state.mobile}    
    error={errors.mobile}
    register={register}    
     />
           </div>
    </Col>
    <Col lg="6" className='mt-4'>
    <div className="form-group">
        <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="companyemail" 
            register={register}
            error={errors.companyemail}
            value={state.email}
            defaultValue={watchAll.companyemail}
            onChange={handleChange('email')}
         />
        </div>
    </Col>
    </Row> */}



                {/* <Row>
                  <Col lg="12">
                    <h4>Social profiles </h4>
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="linkedIn"
                      label="Linkedin URL"
                      type="text"
                      variant="outlined"
                      value={watchAll.linkedIn}
                      onChange={handleChange("linkedIn")}
                      register={register}
                      error={errors.linkedIn}
                      defaultValue={state.linkedIn}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="insta"
                      label="Instagram URL"
                      type="text"
                      variant="outlined"
                      value={watchAll.insta}
                      onChange={handleChange("insta")}
                      register={register}
                      error={errors.insta}
                      defaultValue={state.insta}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="twitter"
                      label="Twitter URL"
                      type="text"
                      variant="outlined"
                      value={watchAll.insta}
                      onChange={handleChange("insta")}
                      register={register}
                      error={errors.insta}
                      defaultValue={state.insta}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormInput
                      className="comp-name mt-4"
                      id="outlined-search"
                      name="Facebook"
                      label="Facebook URL"
                      type="text"
                      variant="outlined"
                      value={watchAll.insta}
                      onChange={handleChange("insta")}
                      register={register}
                      error={errors.insta}
                      defaultValue={state.insta}
                    />
                  </Col>
                </Row> */}

                <h4 className='mt-4' style={{ paddingLeft: '15px' }}>Social Profiles</h4>
                <Row>
                  <Col lg="12">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="linkedIn" ref={register} name="linkedIn" value={state.linkedIn} defaultValue={state.linkedIn} control={control} />
                    <a style={{ float: 'right', marginTop: '1rem' }} href={state.linkedIn_pre + state.linkedIn} target="_blank">View Link</a>
                    <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn1" label="Linkedin Handle" type="text" variant="outlined" value={state.linkedIn_pre + state.linkedIn}
                      onChange={handleChange('linkedIn')}
                      // register={register}
                      // error={!(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.linkedIn)) && state.linkedIn ? {message:'Please Enter Valid LinkedIn Handle', required: true} : errors.linkedIn}
                      error={errors.linkedIn}
                      defaultValue={state.linkedIn_pre + state.linkedIn}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="twitter" ref={register} name="twitter" value={state.twitter} defaultValue={state.twitter} control={control} />
                    <a style={{ float: 'right', marginTop: '1rem' }} href={state.twitter_pre + state.twitter} target="_blank">View Link</a>
                    <FormInput className="comp-name mt-4" id="outlined-search" name="twitter1" label="Twitter Handle" type="text" variant="outlined" value={state.twitter_pre + state.twitter}
                      onChange={handleChange('twitter')}
                      // register={register}
                      // error={!(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.twitter)) && state.twitter ? {message:'Please Enter Valid Twitter Handle', required: true} : errors.twitter}
                      error={errors.twitter}
                      defaultValue={state.twitter}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="insta" ref={register} name="insta" value={state.insta} defaultValue={state.insta} control={control} />
                    <a style={{ float: 'right', marginTop: '1rem' }} href={state.insta_pre + state.insta} target="_blank">View Link</a>
                    <FormInput className="comp-name mt-4" id="outlined-search" name="insta1" label="Instagram Handle" type="text" variant="outlined" value={state.insta_pre + state.insta}
                      onChange={handleChange('insta')}
                      // register={register}
                      // error={!(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.insta)) && state.insta ? {message:'Please Enter Valid Instagram Handle', required: true} : errors.insta}
                      error={errors.insta}
                      defaultValue={state.insta}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="facebook" ref={register} name="facebook" value={state.facebook} defaultValue={state.facebook} control={control} />
                    <a style={{ float: 'right', marginTop: '1rem' }} href={state.facebook_pre + state.facebook} target="_blank">View Link</a>
                    <FormInput className="comp-name mt-4" id="outlined-search" name="facebook1" label="Facebook Handle" type="text" variant="outlined" value={state.facebook_pre + state.facebook}
                      onChange={handleChange('facebook')}
                      // register={register}
                      // error={!(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.facebook)) && state.facebook ? {message:'Please Enter Valid Facebook Handle', required: true} : errors.facebook}
                      error={errors.facebook}
                      defaultValue={state.facebook}
                    />
                  </Col>
                </Row>

                <Card>
                  {/* <CardHeader>
                    <h3 className="text-left">Awards</h3>
                    <p className="text-left">
                      Raise your profile by adding your Awards.
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div className="col-lg-12 retailresellerAwards">
                      
                      <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="awardsImages" /></div>
                      <span onClick={openAwardMediaLib}>
                        {!state?.awards?.length ?
                          <div className="form-group mt-3">
                            <div tabindex="0" class="MuiDropzoneArea-root">
                              <div class="MuiDropzoneArea-textContainer">
                                <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                                <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                              </div></div></div>
                          :
                          <ImageCard list={state.awards} deleteImg={deleteAwards} />
                        }
                      </span>
                    </div>
                  </CardBody> */}

                  <CardBody>
                    <div className="col-lg-12">
                      <h4 style={{ textAlign: "left" }}>
                        Your Company Pictures
                      </h4>
                      <h5 style={{ float: "right" }}>
                        (Maximum 5 Images, Accepts Jpeg / Jpg / Png, File Size
                        Should Not Exceed 2 mb)
                      </h5>
                      <p>Add any certifications related to your company (ex ISO certification)</p>
                      {/* This below div is just used to inherit mui-dropzone css classes */}
                      <div style={{ display: "none" }}>
                        <MultiUpload
                          multiple={true}
                          limit={1}
                          acceptedFiles={["image/*"]}
                          handleChangeFile={handleChangeFileSelect}
                          handleChangeFileDelete={handleChangeFileDelete}
                          input="companyImages"
                        />
                      </div>
                      <span onClick={openMediaLib}>
                        {!mediaState?.companyImages?.length ? (
                          <div className="form-group mt-3">
                            <div tabindex="0" class="MuiDropzoneArea-root">
                              <div class="MuiDropzoneArea-textContainer">
                                <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">
                                  Drag and drop a file here or click
                                </p>
                                <svg
                                  class="MuiSvgIcon-root MuiDropzoneArea-icon"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <ImageCard
                            list={mediaState.companyImages}
                            deleteImg={deleteImg}
                          />
                        )}
                      </span>
                    </div>
                  </CardBody>

                  <CardBody className="rereselbanner">
                    <div className="col-lg-12 ">
                      <h4 style={{ textAlign: "left", display: "inline-block" }}>
                        Banner Image
                      </h4>
                      <h5 style={{ float: "right" }}>
                        (Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed
                        2 mb)
                      </h5>
                      <div className="form-group mt-3" style={{ height: "auto" }}>
                        <BannerUploader
                          img={
                            bannerId
                              ? process.env.REACT_APP_CDN_URL + imageSrc
                              : imageSrc
                                ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc
                                : bannerPic
                          }
                          handleChange={handleChangeBanner}
                          selectImg={selectBannerImg}
                          image={image}
                          handleCropClose={(e) => setImage("")}
                        />
                        {loading && (
                          <div
                            style={{
                              position: "relative",
                              display: "block",
                              top: "50%",
                              left: "50%",
                              zIndex: "1000",
                            }}
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                      <div className="text-right">
                        <span>
                          <i>* 2048 X 400 (Use a resolution of 5:1)</i>
                        </span>
                      </div>
                    </div>
                  </CardBody>

                  <CardHeader>
                    <h3 className="text-left">Welcome Video</h3>
                    <p className="text-left">Add an introduction video that buyers will view on your company profile (max 90 seconds).</p>
                  </CardHeader>
                  <CardBody>
                    <div className="form-group">
                      <form action="/file-upload" className="dropzone">
                        <div className="fallback">
                          <div style={{ flex: 1, display: 'flex' }}><MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2} />
                            {initialVideo && <span style={{ textDecoration: 'underline', color: 'orange', display: 'flex', alignItems: 'center', marginLeft: '2rem', cursor: "pointer" }} onClick={() => setOpen(true)}>Preview Video</span>}</div>
                          <VideoPreview videoModalClose={() => setOpen(false)} isOpen={open} url={initialVideo} />
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="col-lg-12 justify-content-center">
                <h3 className="text-center">Facts & Figures</h3>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-lg-6 mt-2 mb-4">
                    <FormInput
                      className="comp-name mt-2"
                      id="Yearestablishment"
                      name="Yearestablishment"
                      label="Year of Establishment *"
                      placeholder="Year of Establishment"
                      type="text"
                      variant="outlined"
                      value={watchAll.Yearestablishment}
                      defaultValue={state.yearOfEstablishment}
                      error={errors.Yearestablishment}
                      register={register}
                      onChange={handleChange('yearOfEstablishment')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <h4 className="text-sm mb-0">
                      No of Staffs *
                      {!errors.no_of_staffs || state.no_of_staffs ? (
                        ""
                      ) : (
                        <span style={{ color: "#f44336", fontSize: "0.75rem" }}>
                          {" "}
                          Please Select or Enter No Of Employees
                        </span>
                      )}
                    </h4>
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="no_of_staffs"
                      ref={register}
                      name="no_of_staffs"
                      value={state.no_of_staffs}
                      control={control}
                    />
                    <div className="row turnOver_Btn">
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm">
                        <input
                          className={
                            state.no_of_staffs == "1-10"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="1-10"
                          onClick={handleChange("no_of_staffs")}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm">
                        <input
                          className={
                            state.no_of_staffs === "11-50"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="11-50"
                          onClick={handleChange("no_of_staffs")}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm">
                        <input
                          className={
                            state.no_of_staffs === "51-100"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="51-100"
                          onClick={handleChange("no_of_staffs")}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm">
                        <input
                          className={
                            state.no_of_staffs === "101-Above"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="101-Above"
                          onClick={handleChange("no_of_staffs")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12 mt-4 ">
                    <div className="form-group  mt-2">
                      <FormInput
                        id="staffCnt"
                        type="number"
                        name="staffCnt"
                        label="Enter Custom Value *"
                        placeholder="Enter Your Value"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        defaultValue={state.no_of_staffs}
                        value={watchAll.staffCnt}
                        onChange={handleChange('staffCnt')}
                        control={control}
                        register={register}
                        error={errors.staffCnt}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-lg-8 col-md-12 col-sm-12 mt-3">
                    <h4 className="text-sm mb-0">
                      Annual Turnover *
                      {!errors.annualTurnOver || state.annualTurnOver ? (
                        ""
                      ) : (
                        <span style={{ color: "#f44336", fontSize: "0.75rem" }}>
                          {" "}
                          Please Select or Enter Annual Turnover
                        </span>
                      )}
                    </h4>
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="annualTurnOver"
                      name="annualTurnOver"
                      ref={register}
                      value={state.annualTurnOver}
                    />

                    <div className="row turnOver_Btn">
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                        <input
                          className={
                            state.annualTurnOver === "<1M€"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="<1M€"
                          onClick={handleChange("annualTurnOver")}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                        <input
                          className={
                            state.annualTurnOver === "1-10M€"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="1-10M€"
                          onClick={handleChange("annualTurnOver")}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
                        <input
                          className={
                            state.annualTurnOver === "10-100M€"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value="10-100M€"
                          onClick={handleChange("annualTurnOver")}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
                        <input
                          className={
                            state.annualTurnOver === ">100M€"
                              ? "btn btn-secondary text-center btn-lg-sm-md  active"
                              : "btn btn-secondary text-center btn-lg-sm-md"
                          }
                          type="button"
                          id="emprange"
                          name="emprange"
                          value=">100M€"
                          onClick={handleChange("annualTurnOver")}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 ">
                    <div className="form-group ">
                      <FormInput
                        id="turnOver"
                        type="number"
                        name="turnOver"
                        label="Enter Value in M€ *"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">M€</InputAdornment>
                          ),
                        }}
                        value={watchAll.turnOver}
                        defaultValue={state.annualTurnOver}
                        onChange={handleChange('turnOver')}
                        error={errors.turnOver}
                        register={register}
                        control={control}
                      />
                    </div>
                  </div>

                </div>
                <input
                  type="number"
                  style={{
                    position: "absolute",
                    left: "-10000px",
                    top: "auto",
                    width: "1px",
                    height: "1px",
                    overflow: "hidden",
                  }}
                  id="revenue"
                  name="revenue"
                  ref={register}
                  value={+state.domesticRevenue + +state.internationalRev}
                />
                <p className="ml-3">
                  {/* Please specify the breakdown of your revenue in the below
                  categories. */}
                  <span
                    style={{
                      color: "#f44336",
                      float: "right",
                      fontSize: "0.75rem",
                    }}
                  >
                    {errors.revenue
                      ? (typeId == "4" || typeId == "5")
                        ? "The total of Physical Store and Online Store should not exceed 100 %"
                        : errors.revenue.message
                      : ""}
                  </span>
                </p>
                {/* <div
                  style={{
                    padding: "15px 15px 35px",
                    backgroundColor: "#f7fafc",
                  }}
                > */}
                {/* <div className="row"> */}
                {/* <div className="col-lg-4">
                      <h4>
                        {typeId == "2" || typeId == "3"
                          ? "Domestic Revenue in %"
                          : "Physical Store in %"}
                      </h4>
                      <FormInput
                        className="comp-name"
                        name="domestic"
                        variant="outlined"
                        label="Enter Value"
                        placeholder=""
                        value={watchAll.domestic}
                        onChange={handleChange("domesticRevenue")}
                        defaultValue={state.domesticRevenue}
                        register={register}
                        inputProps={{
                          min: "0",
                          max: "100",
                          step: "1",
                          maxlength: 3,
                          pattern: "^[1-9][0-9]?$|^100$",
                          inputMode: "numeric",
                        }}
                        error={errors.domestic}
                        type="number"
                      />
                    </div> */}
                {/* <div className="col-lg-4">
                      <h4>
                        {typeId == "2" || typeId == "3"
                          ? "International Revenue in %"
                          : "Online Store in %"}
                      </h4>
                      <FormInput
                        className="comp-name"
                        name="interRev"
                        variant="outlined"
                        label="Enter Value"
                        placeholder=""
                        onChange={handleChange("internationalRev")}
                        inputProps={{
                          min: "0",
                          max: "100",
                          step: "1",
                          maxlength: 3,
                          pattern: "^[1-9][0-9]?$|^100$",
                          inputMode: "numeric",
                        }}
                        value={watchAll.interRev}
                        defaultValue={state.internationalRev}
                        error={errors.interRev}
                        register={register}
                        type="number"
                      />
                    </div> */}

                {/* {(typeId == "4"||typeId == "5") && (
                      <div className="col-lg-4">
                        <h4>No. of Stores</h4>
                        <FormInput
                          id="storeCount"
                          type="number"
                          name="storeCount"
                          label={"No of Store's *"}
                          InputProps={{
                            inputProps: {
                              max: 100,
                              min: 10,
                            },
                          }}
                          //value={+state.no_of_skus}
                          onChange={handleChange("storeCount")}
                          register={register}
                          defaultValue={state.storeCount}
                          value={watchAll.storeCount}
                          error={errors.storeCount}
                        />
                      </div>
                    )} */}
                {/* </div> */}
                {/* </div> */}



              </CardBody>
            </Card>
            <Card>
              <CardHeader className="col-lg-12 justify-content-center">
                <h3 className="text-center">Documents / Resources</h3>
              </CardHeader>
              <CardBody className="officialDocuments">
                <div className="col-lg-12">
                  <h4 style={{ textAlign: "left", display: "inline-block" }}>
                    Official Documents
                  </h4>
                  <h5 style={{ float: "right" }}>
                    (Maximum 5 Images, Accepts PDF only, File Size Should Not
                    Exceed 2 mb)
                  </h5>
                  {/* <p>upload your company brochure, catalogue, annual report etc...</p>                   */}
                  {/* This below div is just used to inherit mui-dropzone css classes */}
                  <div style={{ display: "none" }}>
                    <MultiUpload
                      multiple={true}
                      limit={1}
                      acceptedFiles={["image/*"]}
                      handleChangeFile={handleChangeFileSelect}
                      handleChangeFileDelete={handleChangeFileDelete}
                      input="bannerImages"
                    />
                  </div>
                  <span
                    onClick={() => {
                      if (mediaState?.productBrochures?.length >= 5) {
                        ToastsStore.error("Maximum 5 Images");
                      } else {
                        setProdImage(true);
                      }
                    }}
                  >
                    {!mediaState?.productBrochures?.length ? (
                      <div className="form-group mt-3">
                        <div tabindex="0" class="MuiDropzoneArea-root">
                          <div class="MuiDropzoneArea-textContainer">
                            <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">
                              Drag and drop a file here or click
                            </p>
                            <svg
                              class="MuiSvgIcon-root MuiDropzoneArea-icon"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PdfCard
                        pdfList={mediaState.productBrochures}
                        deletePdf={pdfDelete}
                        editName={editName}
                      />
                    )}
                  </span>

                  {prodImage && (
                    <ImageLibraryMultipleUploads
                      id={1}
                      isOpen={prodImage}
                      multiple={true}
                      limit={1}
                      acceptedFiles={["application/pdf"]}
                      getBrochuresIds={handleChangeFileSelect}
                      input="productBrochures"
                      aws_folderName="broucher"
                      handleClose={() => setProdImage(false)}
                      documentIds={
                        mediaState?.productBrochures?.length > 0
                          ? mediaState.productBrochures.map((x) =>
                            parseInt(x.documentId)
                          )
                          : []
                      }
                    />
                  )}
                </div>
              </CardBody>
            </Card>
            <div className="text-center mt-4 mb-4">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </Container>
      )}
    </>
  );
};
const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];

const startUp = [
  { name: 'Prototype', id: 1, value: 'PROTOTYPE' },
  { name: 'Shipping to first backers', id: 2, value: 'S_BACKER' },
  { name: 'Shipping in volume', id: 3, value: 'S_VOLUME' },
];

const radioGroupOptions2 = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];

export default RetailReseller;
