import React from "react";
import { Col, Container, Row, Card,CardBody,Button,UncontrolledTooltip,CardImg } from "reactstrap";
import term from "assets/img/terms/terms.jpg";

const TermsConditions = () => {
  return (
<>
    <section className="">
            <div className=" col-lg-12 col-md-8 col-sm-6 mt-2 container-fluid numcard">
              <Card>
            
              <CardBody >
              <div> 
              <hr></hr>   
              <CardImg
                  alt="..."
                  style={{height: "150px",objectFit:"cover"}}
                  src={term}
                  top
                />
                </div>
             

             {/* <Title id="TERMS & CONDITIONS" title="TERMS & CONDITIONS" desc={[""]} />  */}

             <hr></hr>
              <div className="bannerlogo text-center">
              <i style={{color:'#2c9db7'}} class="fas fa-users fa-6x"></i>
              <h1>TERMS & CONDITIONS</h1>
              <hr></hr>
            </div> 
            
             <div className=""> 
             <div className="">
             <div className="updated-time"><h2>Modified on: 6-Oct-2018 1:07 AM</h2></div>
             <p class="h4"> GENERAL CONDITIONS OF USE OF THE CHANNEL HUB.COM WEBSITE</p>
             <p class="h4"></p>
             <p class="h4">CHANNEL HUB (hereinafter the Company") is a simplified joint stock company with a capital of € 1,300.00, registered with the NANTERRE Trade and Companies Register under number 830 495 842, whose registered office is 20 Quai du 4 septembre - 92100 BOULOGNE-BILLANCOURT</p>
             <p class="h4"></p>
             
             <p class="h4">The Company publishes an online platform, accessible at address <a href="https://www.channelhub.net/" target="_blank" style={{color: "blue"}}><u>www.channelhub.net</u></a>&nbsp;(hereinafter the "Service"), offering a digital intermediation system enabling independent Vendors, Distributors, Retailers or Business Developers in their respective sectors of activity to establish contacts.</p>
             <p class="h4"></p>
             
             <p class="h4">This Service is intended only for individual persons (capable adults) or legal entities, acting in a professional capacity (hereinafter referred to as "User(s)").</p>
             <p class="h4"></p>
             <p class="h4">By accepting these Terms of Use ("TOU"), the User represents and warrants that he is acting in a professional capacity. In particular, the User declares that he has the legal capacity to commit his company and subscribe, where applicable, to a paid subscription offered by the Service.</p>
             <p class="h4"></p>
             <p class="h4">The use of certain functionalities by Users may be subject to special conditions which, as the case may be, supplement and/or modify these TOU.</p>
             <p class="h4"></p>
             <p class="h4">Access to the Service requires the User`s prior and unreserved acceptance of these TOU when creating the online account by checking the box provided for this purpose, as well as, where applicable, acceptance of the special conditions applicable.</p>
             <p class="h4"></p>
             <p class="h4">These TOU govern the terms and conditions of access to the Service, as well as the rights and obligations of the User, previously registered, as defined below. The TOU supersede all other documents and constitute the entire terms and conditions applicable to the Service.</p>
             <p class="h4"></p>
             <p class="h4">The Company reserves the right, at any time, to modify the TOS for the evolution needs of the Service. These modifications will come into force on the date of their publication online, after having been brought to the User`s attention.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 1 - Definitions</u></strong></p>
             <p class="h4"></p>
             <p class="h4">Each of the terms mentioned below shall have the following meanings:</p>
             <p class="h4"></p>
             <p class="h4">"Business Developers" means an independent professional or company whose main activity consists of representing one or more vendors in a specific territory and developing the marketing of their products to Distributors and/or Resellers.</p>
             <p class="h4"></p>
             <p class="h4">"User Account" means the online account created by a User to access the content and features of the Service. Users must choose one of the following four profiles when creating their account: Seller, Distributor, Retailer or Commercial Agent.</p>
             <p class="h4"></p>
             <p class="h4">"Content": means all content published by the Service, including text, graphics, tables, photographs and images (animated or not), videos, sounds, hypertext links, as well as distinctive signs, trademarks, logos and associated databases.</p>
             <p class="h4"></p>
             <p class="h4">"Retailer(s)": means a company who sells to the final consumer in quantities suitable for his use (retail trade).</p>
             <p class="h4"></p>
             <p class="h4">"Distributor(s)": means a company whose principal activity consists in distributing in volume, in its name and on its behalf, articles or products of several vendors.</p>
             <p class="h4"></p>
             <p class="h4">"Personal data": refers to the data of Users that can directly or indirectly identify a natural person. Personal data are subject to strict compliance with applicable regulations in force, including Law 78-17 of 6 January 1978 amended by Law 2018-493 of 20 June 2018, and European Regulation 2016/679 of 27 April 2016 (RGPD).</p>
             <p class="h4"></p>
             <p class="h4">"Sample(s)" means one or more specimens of a User`s product, not intended for sale, which may be ordered by another User through the functionality of the Service.</p>
             <p class="h4"></p>
             <p class="h4">"Offer(s)" means a User`s information on one or more products and/or articles published online on the Service ("article card").</p>
             <p class="h4"></p>
             <p class="h4">"User(s)" means the professional(s) who have previously created an online account and who have access to the functionalities of the Service.</p>
             <p class="h4"></p>
             <p class="h4">"Party(ies)" means the User and/or the Company.</p>
             <p class="h4"></p>
             <p class="h4">"Service" means the Company`s website accessible at the following address: www.channelhub.net.</p>
             <p class="h4"></p>
             <p class="h4">"Vendor(s)": means a professional legal or natural person whose principal activity consists in marketing products or articles.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 2 - Purpose</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The purpose of the Service is to provide Users with functionalities enabling them in particular to :</p>
             <p class="h4"></p>
             <p class="h4">- present and reference their activities, services, articles and/or products by providing a certain amount of mandatory information;</p>
             <p class="h4"></p>
             <p class="h4">- Post products, announcements, requests, news, etc. online;</p>
             <p class="h4"></p>
             <p class="h4">- manage the sending/receiving of samples;</p>
             <p class="h4"></p>
             <p class="h4">- make connections and put in relation between Users with a view to selling, providing a service or sharing content.</p>
             <p class="h4"></p>
             <p class="h4">The Service also offers Users the option of subscribing to different types of subscription, free or for a fee, depending on their profile.</p>
             <p class="h4"></p>
             <p class="h4">By using the Service, the User is informed that the Company only intervenes to put the Users in contact, as an intermediation platform, without the Company being a party, in any capacity whatsoever, in transactions (sales/purchases) or partnerships that could then be concluded between the Users.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 3 - Access to the Site</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The Site is available at address www.channelhub.net or at any other address that may be substituted.</p>
             <p class="h4"></p>
             <p class="h4">The User declares that he/she is informed that, in order to access the Service, he/she must have access to the Internet, previously subscribed with the access provider of his/her choice, the cost of which is at his/her expense.</p>
             <p class="h4"></p>
             <p class="h4">The User acknowledges in particular:</p>
             <p class="h4"></p>
             <p class="h4">- that the quality and reliability of transmissions depend on the network infrastructures over which the data circulates, which may lead to network breakdowns or saturations, making it impossible for the User to access the Service;</p>
             <p class="h4"></p>
             <p class="h4">- that it is the User`s responsibility to ensure the security of his equipment and his own data, software or any other equipment he uses, in particular against any contamination by virus or attempted intrusion of which he may be a victim;</p>
             <p class="h4"></p>
             <p class="h4">- that any equipment connected to the Service is and remains under its sole responsibility, in particular in the event of damage resulting directly or indirectly from its connection to the Site.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 4 - Use of the Service</u></strong></p>
             <p class="h4"></p>
             <p class="h4">4.1- Registration and creation of a User Account</p>
             <p class="h4"></p>
             <p class="h4">The use of the Service requires the free creation of a User Account by filling in all the mandatory fields of the online form provided for this purpose. The information that is requested varies according to the User`s profile.&nbsp;</p>
             <p class="h4"></p>
             <p class="h4">The User undertakes to provide complete, accurate and up-to-date information. If any of this information changes, the User must update it to maintain its accuracy. The User is informed that the Company will check the accuracy and completeness of the information transmitted before validating his registration and User Account.</p>
             <p class="h4"></p>
             <p class="h4">After receiving the Company`s confirmation email for the creation of an Account, the User will be invited to check the information provided one last time and to activate his registration via a hypertext link provided for this purpose.</p>
             <p class="h4"></p>
             <p class="h4">4.2- Identifiers / access codes</p>
             <p class="h4"></p>
             <p class="h4">The User is solely responsible for the use of his login and access codes to his User Account. The User ID and password are strictly confidential, personal and non-transferable. In the event of loss or theft of its identifiers and access codes, the User undertakes to inform the Company without delay at the following address: privacy@channel-hub.io</p>
             <p class="h4"></p>
             <p class="h4">4.3- User Profiles</p>
             <p class="h4"></p>
             <p class="h4">When registering, the User must choose the profile corresponding to his activity among the following four: Vendor, Distributor, Retailer or Business Developer. Depending on the selected profile and the User`s choices, the User has access to a certain number of Contents, functionalities, etc.</p>
             <p class="h4"></p>
             <p class="h4">4.4- Deleting the account</p>
             <p class="h4"></p>
             <p class="h4">The User may unsubscribe from the Service at any time and delete his Account by following the instructions below:</p>
             <p class="h4"></p>
             <p class="h4">- by writing to the following e-mail address: privacy@channel-hub.io;</p>
             <p class="h4"></p>
             <p class="h4">- by clicking on the link provided for this purpose: "I unsubscribe".</p>
             <p class="h4"></p>
             <p class="h4">In the event of a breach of applicable law or of any of the obligations set forth in the TOS, the Company reserves the right to delete a User Account, without notice, after informing the User concerned.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 5 – Service Features</u></strong></p>
             <p class="h4"></p>
             <p class="h4">Once registered, in accordance with article 4 above, and after validation of his profile by the Company, the User has access to the following functionalities:</p>
             <p class="h4"></p>
             <p class="h4">- content publications, such as product information, announcements, news, offers, etc.</p>
             <p class="h4"></p>
             <p class="h4">- private messaging system between network users;</p>
             <p class="h4"></p>
             <p class="h4">- confidential and moderated comment system to post comments ;</p>
             <p class="h4"></p>
             <p class="h4">- tools for contacting, ordering and monitoring samples ;</p>
             <p class="h4"></p>
             <p class="h4">- confidential and moderate scoring system between Users.</p>
             <p class="h4"></p>
             <p class="h4">Users have the possibility to contact or connect to other Users members of the network by adding profiles to its prospecting or partner list. If the guest User accepts, the connection is activated.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 6 - Subscriptions</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The Service offers several types of subscription to the User`s choice, such as: "Free", "Basic 1", "Basic 2", "Basic 3", "Pro" etc. The Account holder can subscribe to one of these subscriptions online at any time. Payment for an online subscription is made on the STRIPE© website, a specialized service provider.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 7 – Posting a Product offering </u></strong></p>
             <p class="h4"></p>
             <p class="h4">7.1. Prior to any publication of a Product Offer, Users must complete an product form containing the data and description of the items or products offered, in order to list them online on the Service.</p>
             <p class="h4"></p>
             <p class="h4">If the Company considers that the product sheet, from a strictly software or formatting point of view, needs to be modified, it will submit its modification proposals to the User. The Company makes no assessment of the content of the Offer, which is the sole responsibility of the User.</p>
             <p class="h4"></p>
             <p class="h4">After validation by the User, the Offer is published on the Service and remains available for consultation during a period determined by the User.</p>
             <p class="h4"></p>
             <p class="h4">The User is solely responsible for the content of the Offer published on the Service. It guarantees that the Offer does not contravene public morality, public order or any regulations or legislation in force, in particular relating to competition and the prohibition of the marketing of certain products. It also guarantees that the Offer does not contain any false, misleading or deceptive information and does not in any way infringe any right held by a third party. He assumes full editorial responsibility for the Offers he publishes or distributes.</p>
             <p class="h4"></p>
             <p class="h4">Accordingly, the User indemnifies the Company against any claim or action in connection with an Offer, which may be brought against the User by any third party, and shall bear all damages, costs and expenses incurred by the User by a court decision or settlement agreement signed with such third party.</p>
             <p class="h4"></p>
             <p class="h4">7.2. The User defines in his User Account a geographical sector and the sectors of activity for which he wishes to receive Offers from other Users.</p>
             <p class="h4"></p>
             <p class="h4">The User (Distributor, Retailer or Commercial Agent) receives on his User Account, the list of Offers corresponding to his selection criteria. It can also carry out a free search for Offers from the Service.</p>
             <p class="h4"></p>
             <p class="h4">The User (Commercial Agent) has the possibility to communicate to the Users (Distributors or Retailers) the Offers corresponding to their activities.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 8 - Samples</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The User may purchase from another User a Sample of a product or item sold or offered by the latter.</p>
             <p class="h4"></p>
             <p class="h4">Samples may be obtained directly from the Service, either for a fee or free of charge, depending on the case, by means of a "Sample" feature. In case of online payment, the User is directed to the STRIPE© website, the intermediary chosen by the Company to carry out the transaction.</p>
             <p class="h4"></p>
             <p class="h4">After purchasing the Sample, the User has the possibility, from his User Account, to track his delivery.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 9 - Rating</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The User has the possibility to assign a rating to a User with whom he is (or has been) in relation. The rating may be accompanied by a written observation. The rating is published anonymously on this User`s profile and is freely searchable by other Users who are not in the same category (e.g. a Seller may not view another Seller`s rating).</p>
             <p class="h4"></p>
             <p class="h4">The Company reserves the right to moderate the scores assigned by Users, in particular in the presence of comments that contravene these TOU.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 10 - Obligations of the Company</u></strong></p>
             <p class="h4"></p>
             <p class="h4">10.1 Service Access / Availability</p>
             <p class="h4"></p>
             <p class="h4">The Company has an obligation of best efforts regarding the availability of the Service and undertakes to make its best efforts to access the Service 24 hours a day, 7 days a week. The User is informed that, in any event, the Company cannot guarantee the availability and continuity of the Service`s operation, which depend in particular on the Users` Internet connections, which are subject to considerable technological uncertainty. As such, the Company`s liability may not be sought in the event of impossibility of access to the Service.</p>
             <p class="h4"></p>
             <p class="h4">The Company reserves the right to temporarily interrupt access to the Service, notably for corrective or evolutionary maintenance, or for any other technical need. In this event, the Company will endeavour to inform the Users and, as far as possible, to plan and schedule these technical interventions in such a way as to disrupt the proper functioning of the Service as little as possible and to allow a rapid recovery.</p>
             <p class="h4"></p>
             <p class="h4">10.3 Personal data</p>
             <p class="h4"></p>
             <p class="h4">The Company is responsible for the automated processing of Users` personal data collected for the purposes of the Service. In accordance with the law n°78-17 of January 6, 1978 amended and the European regulation 2016-679 of April 27, 2016 (hereafter "GDPR"), any User has a right of access, rectification, deletion and portability of the data concerning him which he can exercise at the following address by joining a proof of his identity to privacy@channel-hub.io.</p>
             <p class="h4"></p>
             <p class="h4">The Company collects personal data strictly necessary to access the Service and to manage and monitor User Accounts. In all cases, these data are neither transferred nor transmitted to third parties nor used for any purpose other than that for which they were collected.</p>
             <p class="h4"></p>
             <p class="h4">In accordance with articles 24 and following of the GDPR, the Company undertakes to take all useful precautions to preserve the security, integrity and confidentiality of personal data, and in particular to prevent them from being distorted, damaged or communicated to unauthorised persons.</p>
             <p class="h4"></p>
             <p class="h4">The Company may have to subcontract all or part of the services necessary for the delivery of the Service, in particular for hosting the servers on which the Service is installed or backed up. The name and contact details of the hosting provider appear in the Company`s legal notices.</p>
             <p class="h4"></p>
             <p class="h4">In any event, the Company undertakes that the subcontractor to whom it has recourse shall offer the same guarantees of security as regards the implementation of appropriate technical and organisational measures, in accordance with the GDPR.</p>
             <p class="h4"></p>
             <p class="h4">The Company undertakes not to transfer Users` personal data to servers located outside the European Economic Area in a country which does not have a level of data protection equivalent to the GDPR.</p>
             <p class="h4"></p>
             <p class="h4">10.4 Notifications</p>
             <p class="h4"></p>
             <p class="h4">All questions about personal data should be addressed to the Company`s dedicated manager at the following address: privacy@channel-hub.io.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 11 - User`s Obligations</u></strong></p>
             <p class="h4"></p>
             <p class="h4">As the person responsible for browsing the Service and publishing its contents, the User undertakes, without this list being exhaustive:</p>
             <p class="h4"></p>
             <p class="h4">- to respect the legal and regulatory provisions in force, in particular the absolute prohibition of racist, insulting or defamatory statements, incitement to violence, terrorism, crime and, more generally, respect for the rights of all persons;</p>
             <p class="h4"></p>
             <p class="h4">- to use the Service in accordance with its purpose and these TOU ;</p>
             <p class="h4"></p>
             <p class="h4">- not to publish false information;</p>
             <p class="h4"></p>
             <p class="h4">- to respect the intellectual property rights of third parties and other Users of the Service, security and confidentiality of data.</p>
             <p class="h4"></p>
             <p class="h4">The Company reserves the right to refuse access to the Service to any User who does not comply with this clause, without prejudice to any damages that may be claimed. The Company also reserves the right to remove any contribution / comment in the event of any breach of the above rules.</p>
             <p class="h4"></p>
             <p class="h4">The User also undertakes not to intentionally carry out operations that could harm or degrade the Service and, in particular:</p>
             <p class="h4"></p>
             <p class="h4">- not to usurp or attempt to usurp the identity of others ;</p>
             <p class="h4"></p>
             <p class="h4">- not to divert or usurp the identifier / access codes of another User ;</p>
             <p class="h4"></p>
             <p class="h4">- not to transfer or transmit, whether free of charge or for a fee, its identifier / access codes, or any other content to which the User has access through his Account ;</p>
             <p class="h4"></p>
             <p class="h4">- not to hijack or damage the Service or the Company`s server, in particular by using viruses, codes, programs or malicious files.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 12 - Liabilities and Guarantees</u></strong></p>
             <p class="h4"></p>
             <p class="h4">12.1 Liability and Warranties of the Company</p>
             <p class="h4"></p>
             <p class="h4">The Company warrants that it has all rights to the Service it owns. The Company does not provide Users with any advice in the use of the Service which is provided "turnkey" and without any other commitment than those expressly provided for herein.</p>
             <p class="h4"></p>
             <p class="h4">The Company provides online technical support via email and chat accessible as follows: support@channel-hub.io</p>
             <p class="h4"></p>
             <p class="h4">As host, the Company is subject to a reduced liability regime provided for in Article 6 of the law of 21 June 2004 for confidence in the digital economy. As such, the Company can in no way be responsible for the content published by Users and gives no guarantee, express or implied, in this regard.</p>
             <p class="h4"></p>
             <p class="h4">The Company provides no guarantee and shall not be liable for the incorrect or insufficient operation of the Service or the technical means implemented, for the incompatibility of the Service with the User`s software, terminals or hardware, or for the total or partial unavailability, whatever its duration, of all or part of the Service.</p>
             <p class="h4"></p>
             <p class="h4">The Company does not guarantee the conclusion of contractual relationship(s) between Users.</p>
             <p class="h4"></p>
             <p class="h4">12.2. Responsibility and guarantees of the User</p>
             <p class="h4"></p>
             <p class="h4">By accessing the Service, the User declares, guarantees and undertakes to:</p>
             <p class="h4"></p>
             <p class="h4">- not to use any device or software intended to affect or attempt to affect the proper functioning of the Service, or to extract, modify, consult, even in buffer or temporary memory, or for individualized use, all or part of the Service ;</p>
             <p class="h4"></p>
             <p class="h4">- not to access and/or use the Service for illegal purposes and/or with the aim of causing damage to the reputation and image of the Company or more generally to infringe the rights, in particular intellectual property rights, of the Company and/or third parties;</p>
             <p class="h4"></p>
             <p class="h4">- not to use and/or exploit the electronic and/or postal details of other Users of the Service for the purpose of sending unsolicited e-mails.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 13 - Force majeure</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The Parties shall not be liable for loss, damage, delay, non-performance or partial performance resulting directly or indirectly from an event of force majeure, as defined in Article 1218 of the Civil Code.</p><p class="h4"></p>
             <p class="h4"><strong><u>Article 14 - Intellectual Property</u></strong></p>
             <p class="h4"></p>
             <p class="h4">All intellectual property rights, such as copyrights, related rights, trademark rights, database producers` rights, relating to both the structure and the Content of the Service, are the exclusive property of the Company.</p>
             <p class="h4"></p>
             <p class="h4">This Content is made available to the User for the sole use of the Service. The Company grants the User a private, non-exclusive and non-transferable right to use the Content of the Service.</p>
             <p class="h4"></p>
             <p class="h4">Any reproduction, distribution, exploitation, downloading, distribution, extraction, transmission, modification or transformation of all or part of the Service or its Content without the prior and express authorisation of the Company is prohibited and constitutes an infringement of intellectual property rights.</p>
             <p class="h4"></p>
             <p class="h4">Consequently, the User expressly undertakes to:</p>
             <p class="h4"></p>
             <p class="h4">- not to infringe the Company`s intellectual property rights on the Service, its Content, and its trademarks or any other distinctive sign, nor those possibly held by third parties on the content they post on the Service;</p>
             <p class="h4"></p>
             <p class="h4">- not copy, through any process or software, the content of the Service;</p>
             <p class="h4"></p>
             <p class="h4">- not to reproduce, alter or modify the Content or elements present on the Service, without the express prior consent of the Company;</p>
             <p class="h4"></p>
             <p class="h4">- not to extract or reuse, for commercial or private purposes, without the prior written authorization of the Company, a substantial part or not of the content of the Service`s databases.</p>
             <p class="h4"></p>
             <p class="h4">The content distributed on the Service by a User belongs to him. Nevertheless, by distributing them on the Service, the User grants to the Company, free of charge and for the whole world, a non-exclusive right of exploitation, representation and reproduction on all of these contents, and in particular on photographs, texts, videos, illustrations, trademarks, logos or titles, as and when they are published online, as well as a right to use the related intellectual property rights, for the duration of putting the Service online and use of the Service, for the purposes of publication and promotion of the Service.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 15 - Convention of proof</u></strong></p>
             <p class="h4"></p>
             <p class="h4">The systems for recording accesses and actions carried out by Users on the Service are considered authentic between the Parties as proof of the date, duration and terms of use of the Service.</p>
             <p class="h4"></p>
             <p class="h4"><strong><u>Article 16 - Applicable law and jurisdiction</u></strong></p>
             <p class="h4"></p>
            
             <p class="h4">
             These TOU are governed by French law. Any dispute relating to the validity, interpretation, execution, termination of the GCU or the Service shall be subject to the exclusive jurisdiction of the courts and tribunals within the jurisdiction of the City of Paris.</p>
             </div> 
             </div>
             </CardBody >
             </Card>
      
      </div>
          
           
    </section> 
</>
  );
}

export default TermsConditions;