import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem, Collapse, NavbarToggler, DropdownItem, DropdownMenu, NavbarBrand, UncontrolledDropdown, DropdownToggle, CardHeader, Card, CardBody, Row, Col, Button, CardTitle, CardText, CardImg, ButtonGroup
} from "reactstrap";
import GuestFooter from "./GuestNavbar/GuestFooter";
import GuestCategorys from "./GuestCategorys";
import GuestNavBar from "./GuestNavbar/GuestNavbar";
import Footer from "./GuestNavbar/Footer";
import GuestContact from "./GuestContact";
import { Link } from "react-router-dom";
import Guestchannels from "./GuestNew/Guestchannels";
import { Container } from "@material-ui/core";
import axios from "axios";
import NewSub from "./NewSub";
import { commonService } from "_services/common.service";
import GuestViewContact from "../../Hoc/Dialog/GuestViewContact";
import firstImg from 'assets/img/guestpage/business.jpg';
import secondImg from 'assets/img/guestpage/closeup.jpg';
import thiredImg from 'assets/img/guestpage/desk-gadgets.jpg';
import fourth from 'assets/img/guestpage/smartphone-with-earphones.jpg';  //Taiwan
import SimpleImageSlider from "react-simple-image-slider";
import { useForm } from "react-hook-form";
import {history} from "_helpers/history";

export const GuestAbouts = props => {
  const options = top100Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const { register, control } = useForm({ mode: 'onBlur' });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [login, setLogin] = useState(false);
  const [parent, setParent] = useState(null);

  useEffect(() => {
    getBuyers();
  }, []);

  const getBuyers = async () => {
    setLoading(true);
    await axios.get('guest/buyershighlight/1').then(res => {
      setData(res.data.slice(0, 8));
    }).catch(err => setLoading(false));
    setLoading(false);
  }
  const [startIndex, setstartindex] = useState(2);
  useEffect(() => {
    let x = Math.floor((Math.random() * 5) + 0);
    console.log('x', x);
    console.log('setstartindex', startIndex);
    setstartindex(3);
    console.log('setstartindex', startIndex);

  }, [])
  const channelDetailView = (id) => {
    const win = window.open('channelDetailView/' + id);
    // history.push('productDetailView/'+x.productSKU)
    win.focus();
  }

  const [categoriesOption, setCategoriesOption] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState([]);

  const getCategories = async () => {
    let data = {
      //categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null,
      countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null,
      type: typeValue ? typeValue.id : null
    }
    await commonService.postService(data, 'guest/getCategories').then(res => {
      setCategoriesOption(res.data.productCat);
    });
  }


  const [countriesOption, setCountriesOption] = useState([]);
  const [countriesValue, setCountriesValue] = useState([]);
  const [typeOption, setTypeOption] = useState([{ id: '3', name: 'Distributor' }, { id: '4', name: 'Retailer' }, { id: '5', name: 'Reseller' }]);
  const [typeValue, setTypeValue] = useState(null);


  const getCountries = async () => {
    let data = {
      categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null,
      // countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null,
      type: typeValue ? typeValue.id : null
    }
    //let categoryIds = categoriesValue.length ? categoriesValue.map(x => +x.id) : null;
    await commonService.postService(data, 'guest/country/').then(res => {
      setCountriesOption(res.data.countries);
    });
  }

  useEffect(() => {
    getCategories();
    getCountries();
  }, [])

  useEffect(() => {
    if (flag) {
      setViewResult(false);
      if (parent !== 'category')
        getCategories();
      else if (parent !== 'country')
        getCountries();
      getFilterData();
    }
    setFlag(true);
  }, [categoriesValue, countriesValue, typeValue])

  const [search, setSearch] = useState({});
  const [viewResult, setViewResult] = useState(false);

  const getFilterData = async () => {
    let data = {
      filter: {
        categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null,
        countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null,
        type: typeValue ? typeValue.id : null
      }
    }

    await commonService.postService(data, 'guest/buyerSearchCount').then(res => {
      setSearch(res.data);
      if (categoriesValue.length || countriesValue.length || typeValue)
        setViewResult(true);
    })

  }
  const openLogin = () => {
    localStorage.removeItem('guest-product-fav', props.match.params.id);
    localStorage.removeItem('guest-channel-fav', props.match.params.id);
    setLogin(true);
  }
  const handleClose = () => {
    setLogin(false);
  }

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'Category') {
      if (countriesValue.length == 0)
        setParent('category');
      setCategoriesValue(value);
    }
    if (input === 'Country') {
      if (categoriesValue.length == 0)
        setParent('country');
      setCountriesValue(value);
    }
  }


  const changeCategory = (category) => {
    setCategoriesValue(category);

  }

  const [ImgURL, setImgURL] = useState({});

  useEffect(() => {
    const images = [
      { url: secondImg },
      { url: firstImg },
      { url: thiredImg },
      { url: fourth }
    ];
    let x = Math.floor((Math.random() * 4) + 0);
    const ImgURL1 = {
      background: "linear-gradient(to right,#2ca5cdc7, #e8870194), url(" + images[x].url + ") no-repeat top center",
      padding:"72px 72px"
    };
    setImgURL(ImgURL1);
  }, [])
  return (

    <>
      <div className="main-content">
        <GuestNavBar />
        <GuestViewContact type="vendor" open={login} handleClose={handleClose} />
        <div className="heroContainer">
          <div className="hero-section" style={ImgURL}>
            <div style={{ width: '100%' }} class="hero-image1 about-img1">
            </div>
            <div className="searchSection mb-0">
              <h1 className="text-center  ml-3 mt-5 sertitl">Search for distributors, retailers & resellers</h1>
              <CardBody className="d-flex justify-content-center">

                <div className="firstFiltrer mr-3">


                  <Autocomplete
                    id="type"
                    options={typeOption} //{categoriesOption.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    // groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="With Type" variant="outlined" />}
                    onChange={(e, v) => setTypeValue(v)}
                    value={typeValue}
                  // multiple={true}
                  // limitTags={2}
                  />
                </div>

                <div className="firstFiltrer mr-3">

                  <FormAutocompleteGrouping
                    id="Category"
                    name="Category"
                    multiple={true}
                    Options={categoriesOption}
                    //placeholder="Select Product Families"
                    input='Category'
                    label="With Categories"
                    //renderInput={(params) => <TextField {...params} label="With Categories" variant="outlined" />}
                    //errMessage={'Please Select a Product Family'}
                    //error={!errors.productCat || productFamilies.length ?false:true}
                    defaultValue={categoriesValue}
                    value={categoriesValue}


                    getOptionSelected={(options, value) => options.name === value.name}
                    handleChangeSelect={handleChangeSelect}
                    //open={true}
                    control={control}
                  //register={register}
                  limitTags={1}
                  />
                  {/* <Autocomplete
                    id="Category"
                    options={categoriesOption} //{categoriesOption.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    // groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="With Categories" variant="outlined" />}
                    onChange={(e, v) => changeCategory(v)}
                    value={categoriesValue}
                    multiple={true}
                    limitTags={2}
                  /> */}
                </div>
                <div className="secondfiltrer mr-3">

                  <FormAutocompleteGrouping
                    id="Country"
                    name="Country"
                    multiple={true}
                    Options={countriesOption}
                    //placeholder="Select Product Families"
                    input='Country'
                    label="With Countries"
                    //renderInput={(params) => <TextField {...params} label="With Categories" variant="outlined" />}
                    //errMessage={'Please Select a Product Family'}
                    //error={!errors.productCat || productFamilies.length ?false:true}
                    defaultValue={countriesValue}
                    value={countriesValue}
                    isGroup='0'


                    getOptionSelected={(options, value) => options.name === value.name}
                    handleChangeSelect={handleChangeSelect}
                    //open={true}
                    control={control}
                  //register={register}

                  />
                  {/* <Autocomplete
                    id="Category"
                    options={countriesOption} //{options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    // groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="With Countries" variant="outlined" />}
                    onChange={(e, v) => setCountriesValue(v)}
                    value={countriesValue}
                    multiple={true}
                    limitTags={2}
                  /> */}

                </div>
                <div className="ditt">
                  <Button className="btn-icon btn-3 serbtn mr-4 invisible" type="button" onClick={() => getFilterData()}>
                    <span className="btn-inner--icon">
                      <i className="fa fa-search" />
                    </span>
                    <span className="btn-inner--text">Search</span>
                  </Button>
                </div>
              </CardBody>
              {/* <CardBody className="mt-5 countSection">
    <h1 className="text-muted res text-center">MATCHING RESULTS</h1>
    <div className="text-center">
    <div className="countBox d-inline-block">
<h1>3</h1>
    </div>
    <div className="countBox d-inline-block">
    <h1>9</h1>
    </div>
    <div className="countBox d-inline-block">
    <h1>9</h1>
    </div>
    <h1 className="text-uppercase mt-5">companies</h1>
    </div>
  </CardBody> */}
            </div>
            <div className="heroButtons">
              <Link>  <button className="btn btn-md mr-2 free fs1" onClick={openLogin}>Try for Free </button> </Link>
              <a  onClick={() => history.push('/Scheduler')}><button className="btn btn-primary btn-md ml-1 fs1"> Book a Demo</button></a>
              {/* href="https://marketing.channelhub.net/meetings/farouk1" target="_blank" */}
            </div>
          </div>


        </div>
        {/* <div className="heroContainer">
  <div className="hero-section">

  </div>
  </div>  */}

        {viewResult && <Card>
          <CardBody>
            <div className='countshow mt-2'>
              <Container>
                <Row><Col lg='12' className='text-center'>
                  <div>
                    <h2>
                      Numbers Matching your search criteria
                    </h2>
                  </div>
                </Col></Row>

                <div className='mt-2'>
                  <Row>
                    {(!typeValue || typeValue?.id == '3') && <Col lg='4' className='text-center'>
                      <div>
                        <h1>{search?.result?.distributor ? search?.result?.distributor : 0} </h1>
                        <p>
                          No.of Distributors
                        </p>
                      </div>
                    </Col>}
                    {(!typeValue || typeValue?.id == '4') && <Col lg='4' className='text-center bter'>
                      <div>
                        <h1>{search?.result?.retailer ? search?.result?.retailer : 0} </h1>
                        <p>
                          No.of Retailers
                        </p>
                      </div>
                    </Col>}
                    {(!typeValue || typeValue?.id == '5') && <Col lg='4' className='text-center'>
                      <div>
                        <h1>{search?.result?.reseller ? search?.result?.reseller : 0} </h1>
                        <p>
                          No.of Resellers </p>
                      </div>
                    </Col>}
                  </Row></div>

                <Row><Col lg='12' className='text-center'>
                  <div>
                    <p>
                      <button onClick={openLogin} class="btn btn-primary btn-md ml-2">View More Details</button>
                    </p>
                  </div>
                </Col></Row>
              </Container>
            </div>
          </CardBody>
        </Card>
        }
        <Card>
          <CardBody>
              {/* <h1 className='text-center' style={{fontSize:"1.2rem", textAlign:"center", fontFamily: 'Poppins ExtraLight, Poppins, sans-serif'}}>Find new Distributors, Retailers / Resellers and expand your international sales channels
              </h1> */}
              <div style={{ fontSize: '1.2rem', textAlign: 'center', color: 'rgb(50, 50, 93)', padding: '0.8rem', fontWeight:"600"}}>
      Find new Distributors, Retailers / Resellers and expand your international sales channels
          </div> 
              <p className="text-muted" style={{fontSize:"1rem", textAlign:"center", fontFamily: 'Poppins ExtraLight, Poppins, sans-serif'}}>Tell us who you are and we’ll tell you who you should meet
              </p>
              <Container>
                <p style={{fontSize:"15.6px", textAlign:"center", fontFamily: 'Poppins ExtraLight, Poppins, sans-serif', margin:"0"}}>Hundreds of distributors, retailers and resellers use ChannelHub to discover new products. Make sure that your products and your company profile get noticed.
                  Each registered vendor gets a complete company profile including corporate video and a detailed product catalog. Buyers will receive matching product suggestion or vendors suggestion and will be able to contact you or request product samples.
                  Access to our extensive database of distributors and resellers with their full company profile</p>
              </Container>
          </CardBody>
        </Card>

        <Card className="bg-litble">
          <CardBody style={{padding:"0"}}>
          <CardHeader style={{ fontSize:"1.2rem",textAlign:"center",   color: "#32325d",padding:"0.8rem", fontWeight:"600" }}>Recent Buyers</CardHeader>
            <Row >
              {!loading && data.length && data.map((x, i) =>
                <Col lg="3" xl="3" key={i} style={{padding:"1rem"}}>
                  <Card className="pointer guestvendorMax" onClick={() => { channelDetailView(x.channelId) }}>
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}
                      alt="..."
                      src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                      onError={(e) => { e.target.src = process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg' }}
                      className="channeCardlimg p-4"
                    />

                    <CardBody>
                      <div className="text-center">
                        <h5 className="h3 title">
                          <span className="d-block mb-1">{x.companyName}</span>
                          <small className="h4 font-weight-light text-muted">
                            <i class="fas fa-map-marker-alt pr-1"></i>
                            {x.country}
                          </small>
                        </h5>
                      </div>


                      <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
                        <div className="col-lg-12">
                          <h3 className="h3 title text-center text-muted">
                            {x.profileType ? ` ${x.channelTypeId === '2' ? 'Vendor' : x.channelTypeId === '3' ? 'Distributor' : x.channelTypeId === '4' ? "Retailer" : "Reseller"} - ${x.profileType}` : ''}
                          </h3>
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </Col>
              )}
              {/* <Col lg="3" xl="3">

<Card className="pointer">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
              alt="..."
              src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Vyp05ua0_1538388371785_1538389775134.png"
              className="channeCardlimg p-4"
            />

            <CardBody>
              <div className="text-center">
            <h5 className="h3 title">
                  <span className="d-block mb-1">Laura Thomson</span>
                  <small className="h4 font-weight-light text-muted">
                  <i class="fas fa-map-marker-alt pr-1"></i>
                  United Kingdom
                  </small>
                </h5>
                </div>
    
           
              <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
              <div className="col-lg-12">
              <h3 className="h3 title text-center text-muted">
            Type : Specialist Distributor
              </h3>
              </div>
              </div>
             
            </CardBody>
          </Card>
</Col>
<Col lg="3" xl="3">

<Card className="pointer">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
              alt="..."
              src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Qu5SguDU_1600255624909_1600255629883.png"
              className="channeCardlimg p-4"
            />

            <CardBody>
              <div className="text-center">
            <h5 className="h3 title">
                  <span className="d-block mb-1">Laura Thomson</span>
                  <small className="h4 font-weight-light text-muted">
                  <i class="fas fa-map-marker-alt pr-1"></i>
                  United Kingdom
                  </small>
                </h5>
                </div>
    
           
              <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
              <div className="col-lg-12">
              <h3 className="h3 title text-center text-muted">
            Type : Specialist Distributor
              </h3>
              </div>
              </div>
             
            </CardBody>
          </Card>
</Col>
<Col lg="3" xl="3">

<Card className="pointer">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
              alt="..."
              src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Vyp05ua0_1538388371785_1538389775134.png"
              className="channeCardlimg p-4"
            />

            <CardBody>
              <div className="text-center">
            <h5 className="h3 title">
                  <span className="d-block mb-1">Laura Thomson</span>
                  <small className="h4 font-weight-light text-muted">
                  <i class="fas fa-map-marker-alt pr-1"></i>
                  United Kingdom
                  </small>
                </h5>
                </div>
    
           
              <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
              <div className="col-lg-12">
              <h3 className="h3 title text-center text-muted">
            Type : Specialist Distributor
              </h3>
              </div>
              </div>
             
            </CardBody>
          </Card>
</Col>
<Col lg="3" xl="3">

<Card className="pointer">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
              alt="..."
              src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Qu5SguDU_1600255624909_1600255629883.png"
              className="channeCardlimg p-4"
            />

            <CardBody>
              <div className="text-center">
            <h5 className="h3 title">
                  <span className="d-block mb-1">Laura Thomson</span>
                  <small className="h4 font-weight-light text-muted">
                  <i class="fas fa-map-marker-alt pr-1"></i>
                  United Kingdom
                  </small>
                </h5>
                </div>
    
           
              <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
              <div className="col-lg-12">
              <h3 className="h3 title text-center text-muted">
            Type : Specialist Distributor
              </h3>
              </div>
              </div>
             
            </CardBody>
          </Card>
</Col>
<Col lg="3" xl="3">

    <Card className="pointer">
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
                  alt="..."
                  src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Qu5SguDU_1600255624909_1600255629883.png"
                  className="channeCardlimg p-4"
                />

                <CardBody>
                  <div className="text-center">
                <h5 className="h3 title">
                      <span className="d-block mb-1">Laura Thomson</span>
                      <small className="h4 font-weight-light text-muted">
                      <i class="fas fa-map-marker-alt pr-1"></i>
                      United Kingdom
                      </small>
                    </h5>
                    </div>
        
               
                  <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
                  <div className="col-lg-12">
                  <h3 className="h3 title text-center text-muted">
                Type : Specialist Distributor
                  </h3>
                  </div>
                  </div>
                 
                </CardBody>
              </Card>
    </Col>
    <Col lg="3" xl="3">

    <Card className="pointer">
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
                  alt="..."
                  src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Vyp05ua0_1538388371785_1538389775134.png"
                  className="channeCardlimg p-4"
                />

                <CardBody>
                  <div className="text-center">
                <h5 className="h3 title">
                      <span className="d-block mb-1">Laura Thomson</span>
                      <small className="h4 font-weight-light text-muted">
                      <i class="fas fa-map-marker-alt pr-1"></i>
                      United Kingdom
                      </small>
                    </h5>
                    </div>
        
               
                  <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
                  <div className="col-lg-12">
                  <h3 className="h3 title text-center text-muted">
                Type : Specialist Distributor
                  </h3>
                  </div>
                  </div>
                 
                </CardBody>
              </Card>
    </Col>
    <Col lg="3" xl="3">

    <Card className="pointer">
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} 
                  alt="..."
                  src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/Qu5SguDU_1600255624909_1600255629883.png"
                  className="channeCardlimg p-4"
                />

                <CardBody>
                  <div className="text-center">
                <h5 className="h3 title">
                      <span className="d-block mb-1">Laura Thomson</span>
                      <small className="h4 font-weight-light text-muted">
                      <i class="fas fa-map-marker-alt pr-1"></i>
                      United Kingdom
                      </small>
                    </h5>
                    </div>
                  <div className="card-profile-stats text-center d-flex mt-2 justify-content-center p-0">
                  <div className="col-lg-12">
                  <h3 className="h3 title text-center text-muted">
                Type : Specialist Distributor
                  </h3>
                  </div>
                  </div>
                </CardBody>
              </Card>
    </Col> */}
            </Row></CardBody>
        </Card>
        <Guestchannels />
        <NewSub />
        <GuestContact type="vendor" />
        <div className="guestDividcontainer">
          <div className="GuestDivider">

          </div>
        </div>

        <GuestFooter />
      </div>
    </>
  );
}
export default GuestAbouts;

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 }];