import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CardHeader, Card, CardBody, Row, Col, Container } from "reactstrap";
import NextEventPlane from './NextEventPlane';
const NextEventsSlider = () => {


  return (
    <Row>
      <Col lg='12' className='p-0'>
        <Card>
          <CardHeader style={{ fontSize:"1.2rem",textAlign:"center", color: "#32325d",padding:"0.8rem", fontWeight:"600" }}>Next Events</CardHeader>
          <CardBody style={{padding:"1.25rem 1.25rem 0 1.25rem"}}>
              <Container>
               <p style={{fontSize:"1rem", textAlign:"center",fontFamily: 'Poppins ExtraLight, Poppins, sans-serif' }}>ChannelHub is the producer of Channel Summits Events providing the best environment for channel buyers, consumer technology & IT brands to do business and forge new partnerships.
                  Channel Summit events are a high-value opportunity to engage potential business partners through a highly focused series of pre-arranged one-to-one meetings via the ChannelHub platform.</p>

               <p style={{fontSize:"1rem", textAlign:"center", fontFamily: 'Poppins ExtraLight, Poppins, sans-serif', margin:"0" }}>Our 'invitation-only' format with a cap on both exhibitor and delegate numbers ensures a safe, relaxed and focused event for all our attendees.
                </p> 

              </Container>
          </CardBody>
          <CardBody>
            <NextEventPlane />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default NextEventsSlider