import * as React from "react";
import { Grid, Paper } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";

const NextEventPlane = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [events, setEvents] = React.useState([]);

  const getCurrentEvents = async () => {
    try {
      let response = await axios.get(`/publicChannel/nextEvents`)
      const filteredData = response.data.aggregation.filter((item) => {
        return new Date(item.fromDate).getTime() > Date.now();
      }).slice(0, 2).reverse();
      setEvents(filteredData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCurrentEvents();
  }, []);

  const months = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const makeDate = (fromdate, todate) => {
    let startDate =
      fromdate[8] == "0" ? fromdate.substring(9) : fromdate.substring(8);
    let endDate = todate[8] == "0" ? todate.substring(9) : todate.substring(8);
    let year = todate.substring(0, 4);
    let month = months[todate.substring(5, 7)];
    return startDate + " - " + endDate + " " + month + " " + year;
  };

  return (
    <Grid
      container
      margin={"auto"}
      justifyContent={"center"}
      style={{ backgroundColor: "rgb(248, 249, 254)" }}
    >
      {events.map((x) => (
        <Grid
          item
          xs={12}
          md={5}
          spacing={4}
          style={{
            display: "flex",
            width: "85%",
            justifyContent: "center",
            alignItems: "center",
          }}
          mt={4}
          mb={4}
        >
          <Paper
            elevation={2}
            style={{ height: "100%", width: "83%", borderRadius: "10px" }}
          >
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                if (x.eventSiteUrl) {
                  let win = window.open(x.eventSiteUrl);
                  win.focus();
                }
              }}
            >
              <div
                className="list-image"
                style={{
                  width: "100%",
                  minHeight: isLargeScreen ? "490px" : "250px",
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  overflow: "hidden",
                }}
              >
                <img
                  alt="..."
                  src={
                    x.eventImageList
                      ? x.eventImageList.documentUrl
                      : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    margin: "0.5rem 0 0 0",
                    maxWidth: "85%",
                    fontSize: "1rem",
                     textAlign:"center"
                  }}
                >
                  {`${x.name} - ${x.country.country}`}
                </h2>
                <span
                  style={{
                    color: "#2c9db7",
                    margin: "5px 0 0 0",
                    fontSize: "0.85rem",
                    maxWidth: "85%",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {makeDate(x.fromDate, x.toDate)}
                  </span>{" "}
                  <span style={{ fontWeight: "400" }}>{` - ${x.address}`}</span>
                </span>

                <span
                  style={{
                    color: "#777",
                    margin: "4px 0 8px 0",
                    fontSize: "0.875rem",
                    maxWidth: "85%",
                  }}
                >
                  {x.shortDescription}
                </span>
              </div>
            </a>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default NextEventPlane;
