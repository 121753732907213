import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import {Signup,Vendor} from 'views/pages/SignupPage';
import {Verification} from 'views/pages/SignupPage/Verification.js';
import  SignUpHeader  from 'views/pages/SignupPage/SignUpHeader';
import { alertActions } from '../_actions';
import {Login} from '../views/pages/LoginPage/login';
import Schedule from '../views/pages/Demo-Schedule/Schedule.js';

import {DirectLogin} from '../views/pages/LoginPage/directlogin';

import Dashboard from "views/pages/dashboards/Dashboard.js";
//import NewDashboard from "views/pages/dashboards/NewDashboard.js";
import AdminLayout from "../layouts/Admin.js";
import { setAxiosDefauls } from '../_setaxiosconfig/interceptor';
import profile from '../views/pages/profile/profile';
import {Pending} from 'views/pages/SignupPage/Pending.js';
import {ForgotPassword} from 'views/pages/SignupPage/ForgotPassword.js';
import GuestFooter from 'views/pages/GuestPage/GuestNavbar/GuestFooter';
import GuestSection1 from 'views/pages/GuestPage/GuestNavbar/GuestSection1';
import GuestAbout from 'views/pages/GuestPage/GuestNavbar/GuestAbout';
import Wishlist from 'views/pages/GuestPage/GuestNavbar/Wishlist';
import InitialprofileDistributor from 'views/pages/profile/PendingProfiles/InitialprofileDistributor';
import InitialprofileRetailer from 'views/pages/profile/PendingProfiles/InitialprofileRetailer';
import Subscription from 'views/pages/profile/PendingProfiles/Subscription';
import UserSubscription from 'views/pages/User-SubscriptionPage/userSubscription';

import PaypalPayment from 'views/pages/User-SubscriptionPage/payments/paypal';
import EventPaypalPayment from 'views/pages/User-SubscriptionPage/Eventpayments/paypal';

import StripePayment from 'views/pages/User-SubscriptionPage/payments/StripePayment';
import EventStripePayment from 'views/pages/User-SubscriptionPage/Eventpayments/StripePayment';

import StripeAlipayPayment from 'views/pages/User-SubscriptionPage/payments/stripeAlipay';
import EventStripeAlipayPayment from 'views/pages/User-SubscriptionPage/Eventpayments/stripeAlipay';

import PaymentConfirmation from 'views/pages/User-SubscriptionPage/payments/PaymentConfirmation';
import PayCard from "views/pages/profile/PendingProfiles/PayCard";
import PaymentConfirm from "views/pages/profile/PendingProfiles/PaymentConfirm";
import PaymentSuccess from "views/pages/profile/PendingProfiles/PaymentSuccess";
import Events from 'views/Hoc/Dialog/Events';
import Support from 'views/pages/User-SubscriptionPage/HelpReport/Support';
import { ResetPassword } from 'views/pages/SignupPage/ResetPassword';
import { SetPassword } from 'views/pages/SignupPage/setPassword';
import  DigitalWallet  from 'views/pages/profile/DigitalWallet/DigitalWallet';
import {Vendors,Distributor,Retailers,Pricing} from 'views/pages/GuestPage/GuestNavbar/Channels';
import ProductList from 'views/pages/GuestPage/ProductList';
import ChannelList from 'views/pages/GuestPage/ChannelList';
import GuestSalesrep from 'views/pages/GuestPage/GuestNavbar/GuestSalesrep';
import Faq from 'views/pages/GuestPage/GuestNavbar/Faq';
import HelpSupport from 'views/pages/User-SubscriptionPage/HelpReport/HelpSupport';
import GuestDashboard from 'views/pages/GuestPage/GuestDashboard';
import GuesProductList from 'views/pages/GuestPage/GuesProductList';
import GuestCategoryListing from 'views/pages/GuestPage/GuestCategoryListing';
import GeustVendorList from 'views/pages/GuestPage/GeustVendorList';
import GuestAbouts from 'views/pages/GuestPage/GuestAbouts';
import About from 'views/pages/about';
import Guestbuyer from 'views/pages/GuestPage/Guestbuyer';
import PublicChannelDetailView from 'views/pages/Channel-Listing/public-channelDetail-view';
import PublicProductDetail from 'views/pages/Channel-Listing/public-product-detail';
import PublicChannelListingWrapper from 'views/pages/Channel-Listing/public-channel-list-wrapper';
import ScrollToTop from '_helpers/scrollToTop';
import tawkTo from 'tawkto-react';
import Subscriber from 'views/pages/User-SubscriptionPage/HelpReport/Subscriber';
import IdleMonitor from '_hooks/IdleMonitor';
import Newslist from 'views/pages/GuestPage/Newslist';
import MeetRating from "views/pages/Prev-Event/MeetRating";
import EventLoginRedirect from 'views/pages/Events/EventLoginRedirect';
import GuestBuyerNew from 'views/pages/GuestPage/GuestBuyerNew';


setAxiosDefauls();
class App extends React.Component {
     tawkToKey = process.env.REACT_APP_TAWKTOKEY;
     tawkToPropertyId = process.env.REACT_APP_TAWKPROPERTYID;

    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    componentDidMount(){
        tawkTo(this.tawkToPropertyId, this.tawkToKey)
    }

    render() {
        const { alert } = this.props;
        let user = JSON.parse(localStorage.getItem('CHuser'));  
        
        return (
            <div className="main-content">       
             <title>ChannelHub</title>     
            {alert.message &&            
                <div className={`alert ${alert.type}`} >{alert.message}</div>
            }  
                            <IdleMonitor />

                        <Router history={history}>
                            <ScrollToTop /> {/* To avoid scrolling down to the page on redirection */}

                            <Switch>                                
                                <Route exact path="/" component={user?Dashboard:GuestDashboard} />                                
                                <Route exact path="/Login" component={Login} />
                                <Route exact path="/Login/:id" component={Login} />
                                {/* <Route exact path="/newdash" component={NewDashboard} /> */}
                                <Route exact path="/Scheduler" component={Schedule} />
                                <Route exact path="/directlogin/:id" component={DirectLogin} />
                                <Route exact path="/signup" component={SignUpHeader} />
                                <Route exact path="/signupform" component={Signup} />
                                <Route exact path="/distributor" component={Distributor} />
                                <Route exact path="/profile" component={profile} />
                                <Route exact path="/Verification" component={Verification} />
                                <Route exact path="/ForgotPassword" component={ForgotPassword} />
                                <Route exact path="/Pending" component={Pending} />
                                <Route exact path="/footer" component={GuestFooter} />
                                <Route exact path="/vendor" component={Vendors} />
                                <Route exact path="/retailers" component={Retailers} />
                                <Route exact path="/pricing" component={Pricing} />
                                <Route exact path="/gabout" component={user?Dashboard:GuestAbout} />
                                <Route exact path="/wishlist" component={Wishlist} />
                                <Route exact path="/InitialprofileDistributor" component={InitialprofileDistributor} />
                                <Route exact path="/InitialprofileRetailer" component={InitialprofileRetailer} />
                                <Route exact path="/user-subscription" component={UserSubscription} />
                                <Route exact path="/payment/4" component={PaypalPayment} />
                                <Route exact path="/payment/1" component={StripePayment} />
                                <Route exact path="/payment/3" component={StripeAlipayPayment} />
                                <Route exact path="/confirm/:id" component={PaymentConfirmation} />
                                <Route exact path="/events" component={Events} />
                                <Route exact path="admin/Subscription" component={Subscription}/>

                                <Route exact path="admin/alipaypayment" component={StripeAlipayPayment}/>
                                <Route exact path="admin/eventalipaypayment" component={EventStripeAlipayPayment}/>

                                <Route exact path="admin/stripepayment" component={StripePayment}/>
                                <Route exact path="admin/eventstripepayment" component={EventStripePayment}/>

                                <Route exact path="admin/Subscription/Payment" component={PaymentConfirm}/>
                                <Route exact path="admin/Paycard" component={PayCard}/>
                                <Route exact path="admin/Paypal" component={PaypalPayment}/>
                                <Route exact path="admin/eventPaypal" component={EventPaypalPayment}/>

                                <Route exact path="admin/Paymentsuccess" component={PaymentSuccess}/>
                                <Route path="/admin" render={props => <AdminLayout {...props} />} />
                                <Route exact path="/Support" component={Support}/>
                                <Route exact path="/Salesrep" component={user?Dashboard:GuestSalesrep} />
                                <Route exact path="/resetPassword/:token" component={ResetPassword}/>
                                <Route exact path="/setPassword/:token" component={SetPassword}/>
                                <Route exact path="/walletRecharge/:amount" component={DigitalWallet}/>
                                <Route exact path="/products" component={ProductList}/>
                                <Route exact path="/channels" component={ChannelList}/>
                                <Route exact path="/HelpSupport" component={HelpSupport}/>
                                <Route exact path="/faq" component={Faq}/>                                
                                <Route exact path="/guestDashboard" component={user?Dashboard:GuestDashboard} />
                                <Route exact path="/productList" component={user?Dashboard:GuesProductList} />
                                <Route exact path="/newsList" component={Newslist} />
                                <Route exact path="/categorylist" component={user?Dashboard:GuestCategoryListing} />
                                <Route exact path="/channel" component={user?Dashboard:GeustVendorList} />
                                <Route exact path="/iamvendor" component={user?Dashboard:GuestAbouts} />
                                <Route exact path="/iambuyer" component={user?Dashboard:GuestBuyerNew} />
                                <Route exact path="/channelDetailView/:id" component={PublicChannelDetailView} />
                                <Route exact path="/channelView/:id" component={PublicChannelDetailView} />
                                <Route exact path="/productDetailView/:id" component={PublicProductDetail} />

                                <Route exact path="/meetingratings/:id" component={MeetRating} />

                                <Route exact path="/eventLoginRedirect/:id/:channel" component={EventLoginRedirect} />


                                <Route exact path="/productView/:id" component={PublicProductDetail} />
                                <Route exact path="/subscriber" component={Subscriber} />
                                <Route exact path="/Aboutus" component={GuestAbout} />
                                <Route exact path="/about" component={About} />
                                <Route exact path="/ces-breakfast/:id" component={PublicChannelListingWrapper} />
                                <Route exact path="/channel-summit22/:id" component={PublicChannelListingWrapper} />
                                {/* <Redirect to="/guestDashboard" /> */}

                                

                                <Redirect from="*" to="/" /> 
                                
                            </Switch>
                        </Router>
                        </div>
        );
    }
}
function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };